import $ from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.css';
import { Controller } from 'stimulus';
import ElementFinder from '../../helpers/element_finder';
import HttpRequest from '../../helpers/http_request';

export default class extends Controller {
  static targets = [ 'selectTag' ]
  connect() {    
    const selectTag = this.selectTagTarget || ElementFinder.find('select', this.element);
    if (!selectTag) {
      alert('trying to load without selectTag target');
    }

    if (!this.element.dataset.searchUrl) {
      alert('trying to load without searchUrl target');
    }

    $(selectTag).select2({
      placeholder: this.element.dataset.prompt || 'Find ...',
      allowClear: true,
      minimumInputLength: 3,
      width: '100%',
      ajax: {
        url: this.element.dataset.searchUrl,
        dataType: 'json',
      }
    });
    
    const app = this;
    const onSelectedUrl = app.element.dataset.onSelectedUrl;

    if (onSelectedUrl) {
      $(selectTag).on('select2:select', (e) => {
        const options = { contentTarget: app.element.dataset.contentTarget };
        const data = new URLSearchParams(e.params.data).toString();
        let httpRequest = new HttpRequest(onSelectedUrl, 'POST', data, options);

        httpRequest.execute(() => {
          $(selectTag).val(null).trigger('change');
        });
      });
    }
  }
}
