import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['colorField', 'textField'];

  colorFieldChanged(event) {
    this.textFieldTarget.value = event.target.value;
  }

  textFieldChanged(event) {
    this.colorFieldTarget.value = event.target.value;
  }
}
