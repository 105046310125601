import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sameTimeLogistics', 'deliveryDate', 'collectionDate'];

  toggleSameTimeLogistics({ currentTarget }) {
    if (currentTarget.checked) {
      this.sameTimeLogisticsTarget.classList.remove('d-none');
      this.sameTimeLogisticsTarget.classList.add('d-block');
      this.deliveryDateTarget.setAttribute('required', true);
      this.collectionDateTarget.setAttribute('required', true);
    } else {
      this.sameTimeLogisticsTarget.classList.remove('d-block');
      this.sameTimeLogisticsTarget.classList.add('d-none');
      this.deliveryDateTarget.removeAttribute('required');
      this.collectionDateTarget.removeAttribute('required');
    }
  }
}
