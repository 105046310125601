import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['bulkActions', 'selectAllRows'];

  toggleSelectAllRows = (event) => {
    event.preventDefault();
    const checkboxes = document.getElementsByName('select-row');

    /* eslint-disable no-param-reassign */
    checkboxes.forEach((chck) => {
      chck.checked = event.target.checked;
    });
    /* eslint-enable no-param-reassign */

    let numberOfSelected;
    if (!event.target.checked) {
      numberOfSelected = 0;
    } else {
      numberOfSelected = checkboxes.length;
    }

    this.updateNumberOfSelected(numberOfSelected);
    this.toggleBulkActions(!event.target.checked);
  }

  toggleBulkActions = (hide = false) => {
    if (hide) {
      this.bulkActionsTarget.classList.add('d-none');
    } else {
      this.bulkActionsTarget.classList.remove('d-none');
    }
  }

  rowToggled = () => {
    const numberOfSelected = this.getNumberOfSelected();

    this.selectAllRowsTarget.checked = false;
    if (numberOfSelected === 0) {
      this.toggleBulkActions(true);
    } else {
      this.toggleBulkActions();
      this.updateNumberOfSelected(numberOfSelected);
    }
  }

  updateNumberOfSelected = (number) => {
    document.getElementById('number-of-rows-selected').innerHTML = `${number} selected items`;
  }

  getNumberOfSelected = () => {
    const checkboxes = document.getElementsByName('select-row');
    let numberOfSelected = 0;

    checkboxes.forEach((chck) => {
      if (chck.checked) {
        numberOfSelected += 1;
      }
    });

    return numberOfSelected;
  }

  cancelButton = () => {
    const checkboxes = document.getElementsByName('select-row');
    /* eslint-disable no-param-reassign */
    checkboxes.forEach((chck) => {
      chck.checked = false;
    });
    /* eslint-enable no-param-reassign */

    this.selectAllRowsTarget.checked = false;
    this.toggleBulkActions(true);
  }
}
