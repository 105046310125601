export default class ElementFinder {
  static findAll(name, container) {
    const hostContainer = container || document;
    if (name.startsWith('#') || name.startsWith('.')) {
      return hostContainer.querySelectorAll(name);
    } else {
      for (const elName of [ name, `#${name}`, `.${name}`, `#${name.replace(/_/g, '-')}`, `[name$="[${name}]"]`, `[id$='${name}']` ]) {
        const elements = hostContainer.querySelectorAll(elName);
        if (elements.length > 0)
          return elements;
      }
    }

    return [];
  }

  static find(name, container) {
    if (!name) return null;
    return ElementFinder.findAll(name, container)[0];
  }

  static findAllClosest(element, elementIdToFind) {
    const container = element.closest('.tab-pane') || element.closest('form');

    if (!container) {
      console.log(`Cannot find parent tab or form for element ${element.id}`);
      return [];
    }

    return ElementFinder.findAll(elementIdToFind, container);
  }

  static findClosest(element, elementIdToFind) {
    return ElementFinder.findAllClosest(element, elementIdToFind)[0];
  }
}
