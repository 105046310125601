import BaseSpreadsheetController from './base_spreadsheet_controller';

export default class extends BaseSpreadsheetController {
  editableColumns = () => [
    {
      title: 'First name <span class="text-danger">*</span>',
      name: 'firstname',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Surname',
      name: 'surname',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Email <span class="text-danger">*</span>',
      name: 'email',
      width: 250,
      wordWrap: true,
    },
    {
      title: 'Phone number',
      name: 'phone_number',
      width: 150,
    },
    {
      title: 'Company name',
      name: 'company_name',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Address line 1',
      name: 'line_1',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Town/city',
      name: 'city',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Postcode/ZipCode',
      name: 'postcode',
      width: 150,
    },
  ];

  prepareDataForSubmission = () => ({
    customers: this.sheetTarget.jspreadsheet
      .getJson()
      .map(
        ({
          id,
          firstname,
          surname,
          email,
          salutation,
          phone_number,
          company_name,
          line_1,
          city,
          postcode,
        }) => ({
          id,
          firstname,
          surname,
          email,
          salutation,
          phone_number,
          company_name,
          address_attributes: {
            line_1,
            city,
            postcode,
          },
        })
      ),
  });
}
