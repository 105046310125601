import { Controller } from 'stimulus';
import Value from '../../helpers/value';

export default class extends Controller {
  connect() {
    this.nextTabIndex = null;
    this.baseUrl = this.element.dataset.baseUrl;
    this.currentEdit = null;
    const app = this;
    this.element.addEventListener('keyup', (e) => {
      if (e.which == 9) {
        if (e.target.tabIndex > 0) {
          app.nextTabIndex = e.target.tabIndex + 1;
        }
      }
      if (e.which == 27) {
        app.closeEdit(app.currentEdit);
      }

    });

    for (let el of this.element.querySelectorAll('.text-edit')) {
      el.addEventListener('click', (e) => {
        if (e.target.tabIndex > 0) {
          app.nextTabIndex = e.target.tabIndex + 1;
        }
        app.editNode(el);
      });
      el.addEventListener('keyup', (e) => {
        if (e.which == 13) {
          app.editNode(el);
        }
      });
    }
  }

  selectNextTab(e) {
    const nextTabEl = this.element.querySelector(`[tabindex='${this.nextTabIndex}']`);
    if (nextTabEl) {
      nextTabEl.focus();
    }
  }

  editNode(el) {
    this.closeEdit(this.currentEdit);
    el.classList.add('d-none');
    const ctrl = el.parentElement.querySelector('.select-edit');
    if (ctrl) {
      ctrl.classList.remove('d-none');
      const select = ctrl.querySelector('select');

      if (select.tomselect) {
        select.tomselect.on('blur', () => {
          this.closeEdit(el);
        });
      } else {
        select.addEventListener('blur', () => {
          this.closeEdit(el);
        });
      }
    } else {
      const input = document.createElement('input');
      input.type = 'text';
      input.value = el.innerText;
      input.classList.add('form-control', 'text-sm');
      el.parentElement.appendChild(input);
      input.selectionStart = input.selectionEnd = input.value.length;
      input.focus();

      input.addEventListener('blur', () => {
        this.closeEdit(el);
      });
    }

    this.currentEdit = el;
  }

  closeEdit(el) {
    if (!el) {
      return;
    }
    let updatedValue = null;
    const ctrl = el.parentElement.querySelector('.select-edit');
    if (ctrl) {
      ctrl.classList.add('d-none');
      const select = ctrl.querySelector('select');
      if (select.tomselect) {
        updatedValue = select.tomselect.items;
        el.innerText = updatedValue.join(', ')
        select.tomselect.off('blur');
      } else {
        updatedValue = select.value;
        el.innerText = updatedValue;
      }
    } else {
      const input = el.parentElement.querySelector('input');
      this.removeNode(el.parentElement, input);
      input.remove();
      updatedValue = input.value;
      el.innerText = updatedValue;
    }
    el.classList.remove('d-none');
    this.currentEdit = null;
    this.selectNextTab();

    if (Value.isBlank(updatedValue) && el.dataset.suppressBlankUpdates) {
      el.innerText = el.dataset.originalValue;
    } else {
      fetch(`${this.baseUrl}/${el.dataset.guid}?name=${el.dataset.name}&value=${updatedValue}`, {
        method: 'PUT',
      }).then(r => r.text())
        .then(html => {
          const icon = document.createElement('i');
          icon.classList.add('text-success', 'fa', 'fa-check', 'ml-2');
          el.appendChild(icon);
          setTimeout(() => {
            el.removeChild(icon);
            icon.remove();

          }, 2000)
      });
    }
  }

  removeNode(parent, child) {
    for (const n of parent.childNodes) {
      if (child == n) {
        try {
        parent.removeChild(child);
        } catch (err) {}
        break;
      }
    }
  }
}