import { Controller } from 'stimulus';
import ClickTarget from '../../helpers/click_target';
import ButtonClickRequest from '../../helpers/button_click_request';

export default class extends Controller {
  click_and_open_tab(e) {
    let button = new ClickTarget(e);
    this.click(e);
    window.open(button.openTabUrl(), '_blank');
  }

  click(e) {
    new ButtonClickRequest(e).execute();
  }
}
