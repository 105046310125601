import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['line', 'city', 'postcode', 'map'];

  connect() {
    this.lineTarget.addEventListener('change', this.updateMap.bind(this));
    this.cityTarget.addEventListener('change', this.updateMap.bind(this));
    this.postcodeTarget.addEventListener('change', this.updateMap.bind(this));
    this.baseMapUrl = this.element.dataset.baseMapUrl;
    this.updateMap();
  }

  updateMap(e) {
    if (e) {
      e.preventDefault();
    }
    const deliveryAddress = `${this.lineTarget.value}, ${this.cityTarget.value}, ${this.postcodeTarget.value}`;
    if (deliveryAddress.length > 3) {
      this.mapTarget.setAttribute('src', `${this.baseMapUrl}${deliveryAddress}`);
    }
  }
}
