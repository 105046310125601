import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['quantity', 'rate', 'total'];

  connect() {
    this.quantityTargets.forEach((el) =>
      el.addEventListener('change', this.updateTotal.bind(this))
    );
    this.rateTargets.forEach((el) =>
      el.addEventListener('change', this.updateTotal.bind(this))
    );
  }

  updateTotal() {
    this.totalTarget.value = this.quantityTarget.value * this.rateTarget.value;
    this.totalTarget.dispatchEvent(new Event('change'));
  }
}
