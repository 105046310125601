import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['dragItem', 'dropZone'];

  connect() {
    this.cardDate = new Date(this.element.dataset.cardDate);
    this.rangeStartDate = new Date(this.element.dataset.rangeStartDate);
    this.rangeEndDate = new Date(this.element.dataset.rangeEndDate);

    this.selectedItems = [];
  }

  // eslint-disable-next-line class-methods-use-this
  resourcesVisibilityToggled(event) {
    const resourcesList = event.target
      .closest('.card')
      .querySelector('.resource-allocations-list');

    if (event.target.checked) {
      resourcesList.classList.remove('col');
      resourcesList.classList.add('col-8');
    } else {
      resourcesList.classList.remove('col-8');
      resourcesList.classList.add('col');
    }
  }

  resourceItemToggled(event) {
    const { resourceId, resourceType } = event.target.closest(
      '.resource-allocation-badge'
    ).dataset;

    if (event.currentTarget.checked) {
      this.selectedItems.push({
        resourceId,
        resourceType,
      });
    } else {
      this.selectedItems = this.selectedItems.filter(
        (item) =>
          item.resourceId !== resourceId || item.resourceType !== resourceType
      );
    }
  }

  dragStarted(event) {
    const { resourceId, resourceType } = event.target.dataset;

    // Ensure the item being dragged is selected, otherwise make it selected it as well.
    const draggingItemSelected = this.selectedItems.some(
      (item) =>
        item.resourceId === resourceId && item.resourceType === resourceType
    );

    if (!draggingItemSelected) {
      this.selectedItems.push({
        resourceId,
        resourceType,
      });

      $(event.target).find('input[type="checkbox"]').prop('checked', true);
    }

    // Highlight the drop zones available within the card.
    this.dropZoneTargets.forEach((element) => {
      element.classList.add('border-primary');
      $(element).css('border-width', '2px');
      $(element).css('border-style', 'dashed');
    });

    // Clone the item being dragged, and add a counter badge to it indicating the number of items
    // being dragged besides this one. This clone needs to be appendent to the page body so it can
    // be set as the "drag image" on the drag event.
    if (this.selectedItems.length > 1) {
      const nodeClone = event.currentTarget.cloneNode(true);
      nodeClone.style.position = 'absolute';
      nodeClone.style.top = '0px';
      nodeClone.style.left = '-5000px';

      const dragItemsCounter = $('<div />').attr(
        'class',
        'drag-items-counter badge badge-pill badge-primary',
      );
      dragItemsCounter.text(`+${this.selectedItems.length - 1}`);
      dragItemsCounter.appendTo(nodeClone);

      document.body.appendChild(nodeClone);
      event.dataTransfer.setDragImage(nodeClone, 0, 0);
    }
  }

  dragEnd() {
    // Remove the border highlighting the drop zones when the drag has ended.
    this.dropZoneTargets.forEach((element) => {
      element.classList.remove('border-primary');
      $(element).css('border-width', '0');
      $(element).css('border-style', 'none');
    });

    // Remove the cloned element that was set as the drag image from the page.
    $(document.body)
      .find('.drag-items-counter')
      .closest('.resource-allocation-badge')
      .remove();
  }

  // eslint-disable-next-line class-methods-use-this
  dragOver(event) {
    event.preventDefault();
    return true;
  }

  assignSelectedResources(event) {
    const { eventQuoteId } = event.target.closest(
      '.resource-allocations-drop-target',
    ).dataset;

    let data = `start_date=${this.rangeStartDate.toISOString()}&`;
    data += `end_date=${this.rangeEndDate.toISOString()}&`;
    data += `allocation_date=${this.cardDate.toISOString()}&`;

    // Only add the event quote id if it's not null/undefined, to avoid sending "undefined" (string)
    // as the value.
    if (eventQuoteId) {
      data += `event_quote_id=${eventQuoteId}&`;
    }

    this.selectedItems.forEach(({ resourceId, resourceType }, index) => {
      data += `resource_allocations[${index}][resourceable_id]=${resourceId}&`;
      data += `resource_allocations[${index}][resourceable_type]=${resourceType}&`;
    });

    Rails.ajax({
      type: 'post',
      url: this.element.dataset.bulkCreateResourceAllocationUrl,
      dataType: 'script',
      data,
      success: () => {
        this.selectedItems = [];
      },
    });
  }
}
