import IMask from 'imask';
import $ from 'jquery';
import { Controller } from 'stimulus';
import moneyMaskOptions from './moneyMaskOptions';

export default class extends Controller {
  connect() {
    // tooltips
    $(this.element).find('[data-toggle="tooltip"]').tooltip();
    // submit form on enter key press
    $(this.element)
      .find('form')
      .on('keypress', (e) => {
        if (e.which === 13) {
          $(e.currentTarget.form).submit();
        }
      });
    // money masks
    this.applyMoneyMasks();

    // apply carousel JS to carousel elements
    $('.carousel:not(.no-auto-scroll)').carousel();
    // keep paused the .no-auto-scroll carousels
    $('.carousel.no-auto-scroll').carousel({
      pause: true,
      interval: false,
    });

    // show tab if required
    if (window.location.hash && $(window.location.hash)) {
      $('.nav-tabs a[href="' + window.location.hash + '"]').tab('show');
    }

    $('a[data-toggle="tab"]').on('click', function () {
      const hash = $(this).attr('href');
      history.replaceState(null, null, hash);
    });
  }

  applyMoneyMasks() {
    this.element.querySelectorAll('.input-mask-money').forEach((e) => {
      // Keep a reference to the mask so we can keep it in sync with the current value when
      // it's changed programmatically.
      e.mask = IMask(e, moneyMaskOptions);
    });
  }
}
