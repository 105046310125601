import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('submit', this.formSubmitted.bind(this));
  }

  formSubmitted(e) {
    this.element
      .querySelectorAll('button')
      .forEach((btn) => (btn.disabled = true));
  }
}
