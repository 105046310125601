import axios from 'axios';

export default ({
  line1, line2, city, postcode, county,
}) => {
  const query = new URLSearchParams({
    line_1: line1,
    line_2: line2,
    city,
    postcode,
    county,
  }).toString();

  return axios.get(`/api/distance?${query}`);
};
