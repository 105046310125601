import { Controller } from 'stimulus';

// Allow up to 3 text columns on the footer, plus the logo column.
const MAX_FOOTER_COLUMNS = 3;

export default class extends Controller {
  static targets = ['columnEditors', 'addFooterColumnButton'];

  connect() {
    this.updateAddFooterButtonVisibility();
  }

  updateAddFooterButtonVisibility() {
    if (this.columnEditorsTarget.children.length < MAX_FOOTER_COLUMNS) {
      this.addFooterColumnButtonTarget.classList.remove('d-none');
    } else {
      this.addFooterColumnButtonTarget.classList.add('d-none');
    }
  }
}
