import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['modal'];

  openModal() {
    $(this.modalTarget).modal('show');
  }
}
