import TomSelect from 'tom-select';
import 'tom-select/dist/css/tom-select.bootstrap4.css';

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['stockSelect'];

  connect() {
    const { stockSearchPath } = this.element.dataset;
    this.tomSelect = new TomSelect(this.element, {
      create: false,
      valueField: 'id',
      searchField: 'text',
      labelField: 'text',
      shouldLoad: (query) => query.length > 3,
      load: async (query, callback) => {
        const url = `${stockSearchPath}&term=${query}`;
        const r = await fetch(url);
        const body = await r.json();
        callback(body.results[0].children);
      }
    });
  }
}
