import { Controller } from 'stimulus';

// Assumes the controller is mounted on the element that must be hidden when
// the #markForDestruction action is called.
export default class extends Controller {
  static targets = ['destroyInput'];

  markForDestruction(event) {
    event.preventDefault();

    // Set the [_destroy] field to true.
    this.destroyInputTarget.value = true;
    // Visually hide the element in the form.
    this.element.classList.add('d-none');
  }
}
