import { Controller } from 'stimulus';

export default class extends Controller {
  // eslint-disable-next-line class-methods-use-this
  fieldVisibilityChanged(event) {
    const labelInput = event.target.nextElementSibling;

    if (event.target.checked) {
      labelInput.disabled = false;
    } else {
      labelInput.disabled = true;
    }
  }
}
