import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sidebar'];

  toggleSidebar() {
    // if the element already has toggled applied it means its being untoggled
    // so set it to blank
    const cookieVal = this.sidebarTarget.classList.contains('toggled') ? '' : 'toggled';
    document.cookie = `mb_side_bar_toggled_class=${cookieVal};path=/`;
  }
}
