import { Controller } from 'stimulus';
import $ from 'jquery';
import moment from 'moment';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import Chart from 'chart.js';

export default class extends Controller {
  static targets = ['reportDateRange', 'labourTable'];

  connect() {
    this.dateRange = flatpickr(this.reportDateRangeTarget, {
      allowInput: true,
      mode: 'range',
      placeholder: 'Please select a date range',
      onChange: () => this.updatedDateRange(),
    });

    $(this.labourTableTarget).DataTable({
      searching: false,
      conditionalPaging: true,
      conditionalPageLength: true,
      order: [[3, 'asc']],
      dom:
        "<'row'<'col-12 table-responsive text-nowrap'tr>>" +
        "<'d-flex justify-content-end'<'pt-3'p><'pt-3'l>>",
      language: {
        lengthMenu: `
        <div class="form-inline">
          <div class="input-group ml-2">
            <div class="input-group-prepend">
              <div class="input-group-text" for="page-size-select">
                Items per page
              </div>
            </div>
            <select name="page_size" id="page-size-select" class="custom-select">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        `,
      },
    });

    const { dataset: { chartData } } = this.element;
    this.parsedChartData = JSON.parse(chartData);

    const config = {
      type: 'line',
      data: this.parsedChartData,
      options: {
        responsive: true,
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            gridLines: {
              display: true,
            },
            scaleLabel: {
              display: true,
              labelString: 'Labour',
            },
          }],
        },
      },
    };

    const graphElement = document.getElementById('labors-report-graph');
    this.chart = new Chart(graphElement, config);
  }

  updatedDateRange() {
    const {
      selectedDates: [startDate, endDate],
    } = this.dateRange;
    if (!startDate || !endDate) {
      return;
    }

    const urlParams = this.currentUrlSearchParams();
    urlParams.set('start_date', moment(startDate).format());
    urlParams.set('end_date', moment(endDate).format());
    // Delete the currently set date range with the select picker
    urlParams.delete('date_range');

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  predefinedDateRangeSelected(event) {
    const dateRange = event.currentTarget.value;

    const urlParams = this.currentUrlSearchParams();
    // Delete the currently set dates with the calendar picker
    urlParams.delete('start_date');
    urlParams.delete('end_date');

    // Display the results starting from the first page.
    urlParams.delete('page');

    if (dateRange) {
      urlParams.set('date_range', dateRange);
    }

    this.applyURLParams(urlParams);
  }

  updatedLabourType(event) {
    const labourType = event.currentTarget.value;

    const urlParams = this.currentUrlSearchParams();

    // Display the results starting from the first page.
    urlParams.delete('page');

    if (labourType) {
      urlParams.set('labour_type', labourType);
    }

    this.applyURLParams(urlParams);
  }

  currentUrlSearchParams = () => new URLSearchParams(window.location.search);

  applyURLParams = (urlParams) => {
    window.location.search = urlParams;
  };
}
