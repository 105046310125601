import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  connect() {
    this.element.addEventListener('keyup', this.keyPressed.bind(this));
  }

  keyPressed(e) {
    if (e.key === 'Enter') {
      Rails.fire(e.currentTarget.form, 'submit');
      e.preventDefault();
    }
  }
}
