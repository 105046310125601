import { Controller } from 'stimulus';

export default class extends Controller {
  fetch(e) {
    e.preventDefault();
    e.stopPropagation();

    const baseUrl = this.element.dataset.url;
    const query = (!this.element.name || this.element.name == '') ? null : `${this.element.name}=${this.element.value}`;
    const url = query ? (`${baseUrl}${baseUrl.includes('?') ? '&' : '?'}${query}`) : baseUrl;
    fetch(url, {
        headers: { Accept: "text/vnd.turbo-stream.html" },
        method: this.element.dataset.method || 'GET',
      }).then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html));
  }
}