import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['autoClearableInput'];

  clearInputs() {
    this.autoClearableInputTargets.forEach((element) => {
      element.value = ''; // eslint-disable-line no-param-reassign
    });
  }
}
