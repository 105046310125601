import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['inviteCheckbox', 'inviteAll'];

  connect() {
    this.inviteCheckboxTargets.forEach((el) =>
      el.addEventListener('change', this.inviteCbToggled.bind(this))
    );
    this.inviteAllTarget.addEventListener(
      'change',
      this.inviteAllToggled.bind(this)
    );
    const shouldOpen = this.element.dataset.shouldOpenOnRender === 'true';
    if (shouldOpen) {
      $(this.element).modal('show');
    }
  }

  inviteCbToggled(e) {
    const labelText = e.currentTarget.checked ? 'Invite' : 'Do not work with';
    const label = document.querySelector(
      'label[for="' + e.currentTarget.id + '"]'
    );
    label.textContent = labelText;
  }

  inviteAllToggled(e) {
    const check = e.currentTarget.checked;
    this.inviteCheckboxTargets.forEach((el) => {
      el.checked = check;
      const e = new Event('change');
      el.dispatchEvent(e);
    });
  }
}
