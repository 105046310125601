import { Controller } from 'stimulus';
import TomSelect from 'tom-select';
import 'tom-select/dist/css/tom-select.bootstrap4.css';

export default class extends Controller {
  connect() {
    this.tomSelect = new TomSelect(this.element, {
      create: async (input, callback) => {
        const token = document.querySelector('meta[name="csrf-token"]').content;
        const response = await fetch(this.element.dataset.createTagUrl, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({ name: input }),
        });
        const { id, name } = await response.json();

        callback({ value: id, text: name });
      },
    });
  }
}
