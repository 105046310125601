import BaseSpreadsheetController from './base_spreadsheet_controller';

export default class extends BaseSpreadsheetController {
  editableColumns = () => [
    {
      title: 'First name <span class="text-danger">*</span>',
      name: 'firstname',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Second name <span class="text-danger">*</span>',
      name: 'surname',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Email <span class="text-danger">*</span>',
      name: 'email',
      width: 350,
      wordWrap: true,
    },
  ];

  prepareDataForSubmission = () => ({
    users: this.sheetTarget.jspreadsheet.getJson(),
  });
}
