import HttpRequest from '../helpers/http_request';
import ClickTarget from '../helpers/click_target';
import Value from '../helpers/value';

export default class ButtonClickRequest {
  constructor(e, contentTarget) {
    this.e = e;
    this.button = new ClickTarget(this.e);
    this.options = this.button.options || {};
    if (contentTarget) {
      this.options['contentTarget'] = contentTarget;
    }
  }

  execute(callback) {
    this.callback = callback;

    if (this.options.noClickWhenBlank && !Value.isBlank(this.options.field)) {
      const data = this.button.data({ asObject: true });
      const val = data[this.options.field];
      if (Value.isBlank(val)) {
        this.e.stopImmediatePropagation();
        this.e.preventDefault();
        return;
      }
    }

    if (this.options.confirm) {
      this.e.stopImmediatePropagation();

      if (!confirm(this.options.confirm))
        return;
    }

    const app = this;
    const executeFunc = () => {
      app.httpRequestAboutToExecute();

      const httpRequest = new HttpRequest(app.button.url(), app.button.method(), app.button.data(), app.options);

      httpRequest.execute((response) => {
        this.httpRequestExecuted();
        if (app.callback) {
          app.callback(response);
        }
      });
    };

    executeFunc();
  }

  httpRequestAboutToExecute() {
    if (!this.options.doNotDisableButton) {
      if (this.e.preventDefault) {
        this.e.preventDefault();
      }

      this.button.disable();
    }
  }

  httpRequestExecuted() {
    if (!this.options.doNotDisableButton)
      this.button.enable();

    if (this.options.refocus)
      window.scrollTo(0, 0);
  }

}
