import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['editorContainer', 'blocksLayoutSelect'];

  removeCardBodyRow() {
    this.editorContainerTarget.remove();
  }

  blocksLayoutChanged(event) {
    const {
      rowGuid,
      fieldPrefix,
      htmlTargetId,
      blocksLayoutChangedUrl,
    } = this.blocksLayoutSelectTarget.dataset;

    Rails.ajax({
      type: 'get',
      url: blocksLayoutChangedUrl,
      dataType: 'script',
      data: new URLSearchParams({
        html_target_id: htmlTargetId,
        parent_field: fieldPrefix,
        row_guid: rowGuid,
        blocks_layout: event.target.value,
      }).toString(),
    });
  }
}
