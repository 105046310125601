import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['togglableItem'];

  onVisibilityChanged(event) {
    this.togglableItemTargets.forEach((element) => {
      if (event.target.checked) {
        element.classList.remove('d-none');
      } else {
        element.classList.add('d-none');
      }
    });
  }
}
