import { Controller } from 'stimulus';
import FormRequest from '../../helpers/form_request';

export default class extends Controller {
  
  submit(e) {
    new FormRequest(e, e.target.dataset.contentTarget).execute();
  }

}
