import BaseSpreadsheetController from './base_spreadsheet_controller';

export default class extends BaseSpreadsheetController {
  editableColumns = () => [
    {
      title: 'Booking name <span class="text-danger">*</span>',
      name: 'quote_name',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Customer email <span class="text-danger">*</span>',
      name: 'email',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Booking confirmed <br><small>This adds the booking to your calendar.</small>',
      name: 'booking_approved',
      width: 150,
      wordWrap: true,
      type: 'checkbox',
      align: 'center',
    },
    {
      title: 'Delivery date<br><small>YYYY-MM-DD</small>',
      name: 'setup_start',
      width: 125,
      wordWrap: true,
    },
    {
      title: 'Rental date<br><small>YYYY-MM-DD</small>',
      name: 'hire_start',
      width: 125,
      wordWrap: true,
    },
    {
      title: 'Collection date<br><small>YYYY-MM-DD</small>',
      name: 'takedown_start',
      width: 125,
      wordWrap: true,
    },
    {
      title: 'Address line 1',
      name: 'line_1',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Town/city',
      name: 'city',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Postcode/ZipCode',
      name: 'postcode',
      width: 150,
    },
  ];

  prepareDataForSubmission = () => ({
    event_quotes: this.sheetTarget.jspreadsheet
      .getJson()
      .map(
        ({
          id,
          quote_name,
          email,
          booking_approved,
          setup_start,
          hire_start,
          takedown_start,
          line_1,
          city,
          postcode,
        }) => ({
          id,
          quote_name,
          booking_approved,
          setup_start,
          hire_start,
          takedown_start,
          address_attributes: {
            line_1,
            city,
            postcode,
          },
          customer_attributes: {
            email,
          },
        }),
      ),
  });
}
