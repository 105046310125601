import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['tabsContainer'];

  connect() {
    const tabLinks = this.tabsContainerTarget.querySelectorAll('a[data-toggle="pill"]');
    $(tabLinks).on('click', (event) => {
      window.history.pushState(null, null, event.target.hash);
      document.querySelector('.app-container').scrollTo(0, 0);
    });

    this.selectActiveTab();
  }

  selectActiveTab() {
    const activeSection = window.location.hash.replace('#', '');
    let activeTab;
    if (activeSection) {
      activeTab = this.tabsContainerTarget.querySelector(`#${activeSection}-tab`);
    } else {
      activeTab = this.tabsContainerTarget.firstElementChild;
    }

    $(activeTab).tab('show');
  }
}
