import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['addNoteForm']

  addNoteToggled(event) {
    event.preventDefault();
    this.addNoteFormTarget.classList.remove('d-none');
    this.addNoteFormTarget.classList.add('d-block');
  }

  // eslint-disable-next-line class-methods-use-this
  toggledNoteVisibilityOption(event) {
    Rails.fire(event.currentTarget.form, 'submit');
  }
}
