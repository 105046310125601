import { Controller } from 'stimulus';
import Element from '../../helpers/element';
import ElementFinder from '../../helpers/element_finder';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['reqQuantity'];

  addMarquee() {
    this.reqQuantityTarget.value = 1;

    const elements = ElementFinder.findAll('.quote-wizard-marquee-selection-btn');
    for (const el of elements) {
      Element.disable(el);
    }
    const form = this.element.closest('form');
    Rails.fire(form, 'submit')
  }
}
