import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['rate', 'quantity', 'total'];

  connect() {
    this.rateTarget.addEventListener('change', this.recalcTotal.bind(this));
    this.quantityTarget.addEventListener('change', this.recalcTotal.bind(this));
  }

  recalcTotal() {
    const total = this.rateTarget.value * this.quantityTarget.value;
    this.totalTargets.forEach((t) => t.value = total);
  }

  removeLineItem = (e) => {
    const lineItemRow = e.currentTarget.closest('tr');
    const guid = lineItemRow.dataset.lineItemGuid;
    const tbody = e.currentTarget.closest('tbody');
    tbody.querySelectorAll(`tr.line-item-guid-${guid}`).forEach((n) => n.remove());
  }
}
