import IMask from 'imask';
import _ from 'lodash';
import Rails from '@rails/ujs';
import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['stockSelect', 'stockRate', 'stockCategory',
    'stockQuantity', 'stockTotal', 'stockName', 'submitButton', 'isOptionalExtra'];

  connect() {
    // mask the money fields
    const moneyMaskOptions = {
      mask: Number,
      scale: 2,
      signed: true,
      thousandsSeparator: ',',
      padFractionalZeros: true,
      radix: '.',
    };
    this.stockRateMask = IMask(this.stockRateTarget, moneyMaskOptions);
    this.stockTotalMask = IMask(this.stockTotalTarget, moneyMaskOptions);

    // when a stock item is selected update the stock form with the details
    const updateForm = (e) => {
      const { category, rate, text: name } = e.params.data;
      this.stockNameTarget.value = name;
      this.stockCategoryTarget.value = category;
      this.stockRateMask.unmaskedValue = rate;
      this.stockQuantityTarget.value = 1;
      this.formUpdated();
    };
    $(this.stockSelectTarget).on('select2:select', updateForm.bind(this));
  }

  disconnect() {
    $(this.stockSelectTarget).off('select2:select');
    if (this.hasIsOptionalExtraTarget) {
      $('#add-optional-extra-modal').off('hidden.bs.modal');
    } else {
      $('#add-requirement-modal').off('hidden.bs.modal');
    }
  }

  saveAndDismiss(e) {
    e.preventDefault();
    const submitForm = () => Rails.fire(this.stockRateTarget.form, 'submit');
    if (this.hasIsOptionalExtraTarget) {
      $('#add-optional-extra-modal').on('hidden.bs.modal', submitForm.bind(this));
      $('#add-optional-extra-modal').modal('hide');
    } else {
      // wait for the modal to be dismissed completely before submitting the form
      $('#add-requirement-modal').on('hidden.bs.modal', submitForm.bind(this));
      $('#add-requirement-modal').modal('hide');
    }
  }

  formUpdated() {
    // when the quantity or rate is updated, update the total
    const rate = this.stockRateMask.typedValue;
    const quantity = this.stockQuantityTarget.value;
    this.stockTotalMask.typedValue = rate * quantity;
    const formIncomplete = [
      this.stockNameTarget.value,
      this.stockCategoryTarget.value,
      this.stockRateMask.unmaskedValue,
      this.stockQuantityTarget.value,
    ].some((e) => _.isEmpty(e));
    this.submitButtonTarget.disabled = formIncomplete;
  }

  customToggled(e) {
    this.addingCustomItem = e.currentTarget.checked;
    this.stockSelectTarget.disabled = this.addingCustomItem;
    if (this.addingCustomItem) {
      this.stockSelectTarget.value = 0;
      this.stockCategoryTarget.value = 'Custom';
      this.stockCategoryTarget.disabled = true;
      this.stockNameTarget.value = '';
      this.stockRateMask.unmaskedValue = '';
      this.stockQuantityTarget.value = 1;
      this.stockTotalMask.unmaskedValue = '';
    }
    this.formUpdated();
  }
}
