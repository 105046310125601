import BaseSpreadsheetController from './base_spreadsheet_controller';

export default class extends BaseSpreadsheetController {
  editableColumns = () => [
    {
      title: 'First name <span class="text-danger">*</span>',
      name: 'firstname',
      width: 100,
      wordWrap: true,
    },
    {
      title: 'Surname',
      name: 'surname',
      width: 100,
      wordWrap: true,
    },
    {
      title: 'Phone number',
      name: 'phone_number',
      width: 150,
    },
    {
      title: 'Job title <span class="text-danger">*</span>',
      name: 'job_title',
      width: 100,
      wordWrap: true,
    },
    {
      title: 'Job title abbreviation <span class="text-danger">*</span>',
      name: 'job_title_abbreviation',
      width: 100,
      wordWrap: true,
    },
    {
      title: 'Hourly rate',
      name: 'hourly_rate',
      type: 'numeric',
      align: 'right',
      mask: '0.00',
      width: 75,
    },
  ];

  prepareDataForSubmission = () => ({
    employees: this.sheetTarget.jspreadsheet.getJson(),
  });
}
