import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['columnDataTypeSelect', 'editorContainer'];

  removeFooterColumn() {
    this.editorContainerTarget.remove();
  }

  columnDataTypeChanged(event) {
    const {
      replaceColumnDataTypeUrl,
      htmlTargetId,
      fieldPrefix,
    } = this.columnDataTypeSelectTarget.dataset;

    Rails.ajax({
      type: 'get',
      url: replaceColumnDataTypeUrl,
      dataType: 'script',
      data: new URLSearchParams({
        html_target_id: htmlTargetId,
        parent_field: fieldPrefix,
        column_data_type: event.target.value,
      }).toString(),
    });
  }
}
