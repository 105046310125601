import { Controller } from 'stimulus';
import IMask from 'imask';
import moneyMaskOptions from '../shared/moneyMaskOptions';

export default class extends Controller {
  static targets = ['percentageInput', 'percentageFormField', 'amount'];

  connect() {
    if (this.hasPercentageInputTarget) {
      this.percentageMask = IMask(this.percentageInputTarget, {
        mask: Number,
        scale: 5,
        precision: 10,
        signed: false,
        radix: '.'
      });
      this.percentageMask.on('accept', () => {
        const perc = this.percentageMask.typedValue / 100.0;
        this.percentageFormFieldTarget.value = perc;
        this.amountMask.typedValue = perc * this.eventQuoteTotal;
      });
      this.amountMask = IMask(this.amountTarget, moneyMaskOptions);
    }
    this.eventQuoteTotal = this.data.get('quote-total');
  }
}
