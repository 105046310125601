import BaseSpreadsheetController from './base_spreadsheet_controller';

export default class extends BaseSpreadsheetController {
  connect() {
    this.stockVariantParents = JSON.parse(this.element.dataset.stockVariantParents);
    this.marquees = JSON.parse(this.element.dataset.marquees);
    this.stockName = this.element.dataset.stockName;
    super.connect();
  }

  editableColumns = () => [
    {
      title: `Select the tent to add the ${this.stockName.toLowerCase()} to <span class="text-danger">*</span>`,
      name: 'marquee_id',
      width: 175,
      type: 'dropdown',
      source: this.marquees,
    },
    {
      title: `Select the parent ${this.stockName.toLowerCase()} product <span class="text-danger">*</span>`,
      name: 'stock_variant_parent_id',
      width: 175,
      type: 'dropdown',
      source: this.stockVariantParents,
    },
    {
      title: `Custom bay ${this.stockName} product name <span class="text-danger">*</span>`,
      name: 'name',
      width: 425,
      wordWrap: true,
    },
    {
      title: 'Price <span class="text-danger">*</span>',
      name: 'rate',
      width: 85,
      type: 'numeric',
      align: 'right',
      mask: '0.00',
    },
  ];

  prepareDataForSubmission = () => ({
    stock: this.sheetTarget.jspreadsheet
      .getJson()
      .map(
        ({
          id,
          marquee_id,
          stock_variant_parent_id,
          name,
          rate,
        }) => ({
          id,
          marquee_id,
          stock_variant_parent_id,
          name,
          rate,
        })
      ),
  });
}
