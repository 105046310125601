import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['categorySelect', 'feeRow'];

  connect() {
    if (this.hasCategorySelectTarget) {
      const { currentCategories } = this.feeRowTarget.dataset;
      this.currentAddedCategories = JSON.parse(currentCategories);

      $(this.categorySelectTarget).select2({
        placeholder: 'Find categories',
        minimumInputLength: 3,
        width: '100%',
        multiple: true,
        data: this.currentAddedCategories,
        ajax: {
          url: '/agent/fees/stock_categories',
          dataType: 'json',
        },
      });
    }
  }

  showArchived = (e) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('show_archived_fees', e.currentTarget.checked === true);
    window.location.search = urlParams;
  }

  toggleArchived(event) {
    const stockId = this.tableRowTarget.dataset.id;

    // Apply the table-danger class to the row + the details row beneath it
    const { parentNode } = this.tableRowTarget;

    if (event.currentTarget.checked) {
      this.tableRowTarget.classList.add('table-danger');
      const detailsNode = parentNode.querySelector(`#details-row-${stockId}`);
      if (detailsNode) {
        detailsNode.classList.add('table-danger');
      }
    } else {
      this.tableRowTarget.classList.remove('table-danger');
      const detailsNode = parentNode.querySelector(`#details-row-${stockId}`);

      if (detailsNode) {
        detailsNode.classList.remove('table-danger');
      }
    }
  }
}
