import { Controller } from 'stimulus';
import HttpRequest from '../../helpers/http_request';

export default class extends Controller {
  static targets = [];

  connect() {
    this.isRebuilding = this.element.dataset.loadListRebuilding == 'true';

    if (this.isRebuilding) {
      this.disableTargetElement();
    } else {
      // remove overlays
      document
        .querySelectorAll(
          '.hide-when-load-list-rebuilding .load-list-rebuilding-ov'
        )
        .forEach((e) => e.remove());
    }
  }

  disableTargetElement() {
    document
      .querySelectorAll('.hide-when-load-list-rebuilding')
      .forEach(this.insertOverlay.bind(this));
  }

  insertOverlay(e) {
    const ov = document.createElement('div');
    ov.classList.add('load-list-rebuilding-ov');
    ov.style.cssText = `position: absolute; background-color: rgba(0, 0, 0, 0.5); z-index: 100; width: ${e.offsetWidth}px; height: ${e.offsetHeight}px;`;
    e.insertBefore(ov, e.firstChild);
    new ResizeObserver(this.resizeOv.bind(this)).observe(e);
  }

  resizeOv(e) {
    e.forEach((resizeEvent) => {
      const ov = resizeEvent.target.querySelector('.load-list-rebuilding-ov');
      if (ov) {
        ov.style.cssText = `position: absolute; background-color: rgba(0, 0, 0, 0.5); z-index: 100; width: ${resizeEvent.target.offsetWidth}px; height: ${resizeEvent.target.offsetHeight}px;`;
      }
    });
  }
}
