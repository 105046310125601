import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['messagesList'];

  connect() {
    if (this.hasMessagesListTarget) {
      this.messagesListTarget.scroll({
        top: this.messagesListTarget.scrollHeight,
      });
    }
  }

  reloadPage = () => {
    window.location.reload();
  }
}
