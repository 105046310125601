import toastr from 'toastr';
import Value from '../../helpers/value';
import Element from '../../helpers/element';
import Rails from '@rails/ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const eventToSubmitFormOn =
      this.element.dataset.submitOnEvent || 'focusout';
    this.element.addEventListener(
      eventToSubmitFormOn,
      this.submitForm.bind(this)
    );
  }

  submitForm(e) {
    if (Element.isButton(e.target)) {
      return;
    }

    const form = e.currentTarget.form || e.currentTarget.closest('form');
    
    if (this.element.dataset.showMessages) {
      form.addEventListener('ajax:success', (e) => { this.onSuccess(e) });
      form.addEventListener('ajax:error', (e) => { this.onError(e) });
    }
    
    Rails.fire(form, 'submit');
  }

  onSuccess(e) {
    toastr.remove();
    toastr.success('Updated successfully', '', { timeOut: 500 });
  }

  onError(e) {
    let [data, status, xhr] = e.detail;
    toastr.remove();
    toastr.error(`Unhandled error: ${Value.toString(data)}`);
  }


}
