import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const messageElementClass = this.element.dataset.messageElementClass;
    const messages = Array.from(
      this.element.querySelectorAll(messageElementClass)
    );
    const m = messages.pop();
    // m.scrollIntoView();
  }
}
