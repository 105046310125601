import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  connect() {
    const { remote, filters, filtersUpdateUrl } = this.element.dataset;

    if (remote === 'true') {
      this.remote = true;
      this.currentFilters = JSON.parse(filters);
      this.filtersUpdateUrl = filtersUpdateUrl;
    }
  }

  currentUrlSearchParams() {
    if (this.remote) {
      return new URLSearchParams(this.currentFilters);
    }

    return new URLSearchParams(window.location.search);
  }

  // eslint-disable-next-line class-methods-use-this
  pageSizeUpdated(event) {
    const urlParams = this.currentUrlSearchParams();
    urlParams.set('page_size', event.currentTarget.value);

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  applyURLParams(urlParams) {
    if (!this.remote) {
      window.location.search = urlParams;
      return;
    }

    Rails.ajax({
      type: 'GET',
      url: `${this.filtersUpdateUrl}?${urlParams.toString()}`,
      dataType: 'script',
    });
  }
}
