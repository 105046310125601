import Rails from '@rails/ujs';

import BulkActionsController from '../shared/bulk_actions_controller';

export default class extends BulkActionsController {
  selectedStockParams = () => {
    const checkboxes = document.getElementsByName('select-row');
    const params = new URLSearchParams();

    checkboxes.forEach((chck, index) => {
      if (chck.checked) {
        params.append(`collection_note[returned_items_attributes][${index}][stock_id]`, chck.dataset.stockId);
      }
    });

    return params;
  }

  updateStockQuantitiesRequest = (event) => {
    event.preventDefault();
    const data = this.selectedStockParams();
    const serializedData = data.toString();

    Rails.ajax({
      type: 'PUT',
      url: event.target.dataset.updateStockQuantitiesPath,
      data: serializedData,
    });
  }
}
