import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'additionalMarqueeLengthInput',
    'additionalMarqueePriceInput',
  ];

  connect() {
    this.sectionalMarquee = this.element.dataset.sectionalMarquee === 'true';
  }

  rateChanged(event) {
    if (!this.sectionalMarquee) {
      this.additionalMarqueePriceInputTarget.value = event.target.value;
    }
  }

  startingMarqueeLengthChanged(event) {
    if (!this.sectionalMarquee) {
      this.additionalMarqueeLengthInputTarget.value = event.target.value;
    }
  }
}
