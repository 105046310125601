import BaseSpreadsheetController from './base_spreadsheet_controller';

export default class extends BaseSpreadsheetController {
  editableColumns = () => [
    {
      title: 'Business name <span class="text-danger">*</span>',
      name: 'name',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Contact name',
      name: 'contact_name',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Email',
      name: 'email',
      width: 250,
      wordWrap: true,
    },
    {
      title: 'Phone number',
      name: 'telephone',
      width: 150,
    },
    {
      title: 'Address line 1',
      name: 'line_1',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Town/city',
      name: 'city',
      width: 150,
      wordWrap: true,
    },
    {
      title: 'Postcode/ZipCode',
      name: 'postcode',
      width: 150,
    },
  ];

  prepareDataForSubmission = () => ({
    vendors: this.sheetTarget.jspreadsheet
      .getJson()
      .map(
        ({
          id,
          name,
          contact_name,
          email,
          telephone,
          line_1,
          city,
          postcode,
        }) => ({
          id,
          name,
          contact_name,
          email,
          telephone,
          address_attributes: {
            line_1,
            city,
            postcode,
          },
        })
      ),
  });
}
