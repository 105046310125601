import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.cardClicked.bind(this));
    this.responseIsSelected =
      this.element.dataset.responseCardSelected === 'true';
    // if (this.responseIsSelected) {
    //   this.element.scrollIntoView(true);
    // }
  }

  cardClicked(e) {
    const vendorLeadGuid =
      e.currentTarget.closest('.response-card').dataset.vendorLeadGuid;

    const url = new URL(window.location);
    const query = new URLSearchParams(url.search);
    query.set('vendor_lead_guid', vendorLeadGuid);
    window.location = `${url.origin}${url.pathname}?${query}`;
  }
}
