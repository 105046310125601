import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['reportTable', 'searchInput'];

  connect() {
    if (this.hasReportTableTarget) {
      this.initReportTable();
    }
  }

  initReportTable() {
    const dataTableConfig = {
      conditionalPaging: true,
      conditionalPageLength: true,
      order: [],
      dom: `
        <'row'
          <'col-12 table-responsive text-nowrap'tr>
        >
        <'row'
          <'col-12 d-flex justify-content-end'
            <'pt-3'p><'pt-3'l>
          >
        >
      `,
      language: {
        searchPlaceholder: 'Search report',
        search: '',
        lengthMenu: `
        <div class="form-inline">
          <div class="input-group ml-2">
            <div class="input-group-prepend">
              <div class="input-group-text" for="page-size-select">
                Items per page
              </div>
            </div>
            <select name="page_size" id="page-size-select" class="custom-select">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        `,
      },
    };

    this.dataTable = $(this.reportTableTarget).DataTable(dataTableConfig);
  }

  updateSearchTerm(event) {
    this.dataTable.search(event.target.value).draw();
  }
}
