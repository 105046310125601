import { Controller } from 'stimulus';

export default class extends Controller {
  // eslint-disable-next-line class-methods-use-this
  groupByChanged(event) {
    const { value } = event.currentTarget;
    const url = new URL(window.location);
    url.searchParams.set('group_by', value);
    window.location = url.href;
  }
}
