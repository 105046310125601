import humps from 'humps';
import axios from 'axios';

export default async ({ startDate, endDate }) => {
  const r = await axios.get(`/api/bookings/stats?start_date=${startDate}&end_date=${endDate}`);
  if (r.status === 200) {
    return humps.camelizeKeys(r.data);
  }
  throw new Error('Failed to retrieve booking stats');
};
