import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'actionDetail',
  ];

  actionTypeUpdated(e) {
    // find the matching action type details and show it (hide the others)
    this.actionDetailTargets.forEach((t) => {
      t.classList.add('d-none');
      t.querySelectorAll('input, select').forEach((input) => {
        const el = input;
        el.disabled = true;
        el.required = false;
      });
    });
    const section = this.actionDetailTargets.find(
      (t) => t.dataset.adjustmentType === e.currentTarget.value,
    );
    section.classList.remove('d-none');
    section.querySelectorAll('input, select').forEach((input) => {
      if (!input.classList.contains('stock-id-input')) {
        const el = input;
        el.disabled = false;
        el.required = true;
      }
    });
  }
}
