import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['contentInput'];

  sendMessage(e) {
    const messageContent = e.currentTarget.dataset.suggestedMessageContent;
    this.contentInputTarget.value = messageContent;
    this.contentInputTarget.form.requestSubmit();
  }
}
