import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['tableRow'];

  toggleArchived(event) {
    const stockId = this.tableRowTarget.dataset.id;

    // Apply the table-danger class to the row + the details row beneath it
    const { parentNode } = this.tableRowTarget;

    if (event.currentTarget.checked) {
      this.tableRowTarget.classList.add('table-danger');
      const detailsNode = parentNode.querySelector(`#details-row-${stockId}`);
      if (detailsNode) {
        detailsNode.classList.add('table-danger');
      }
    } else {
      this.tableRowTarget.classList.remove('table-danger');
      const detailsNode = parentNode.querySelector(`#details-row-${stockId}`);

      if (detailsNode) {
        detailsNode.classList.remove('table-danger');
      }
    }
  }
}
