import { Controller } from 'stimulus';
import $ from 'jquery';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['bulkActions', 'selectAllItems', 'createPoForm', 'vendorsSelect'];

  connect() {
    const vendorsSearchPath = this.createPoFormTarget.dataset.showVendorsListPath;
    $(this.vendorsSelectTarget).select2({
      placeholder: 'Find a vendor...',
      allowClear: true,
      width: '100%',
      ajax: {
        url: vendorsSearchPath,
        dataType: 'json',
      },
    });
  }

  toggleSelectAllStockItems = (event) => {
    event.preventDefault();
    const checkboxes = document.getElementsByName('select-stock-item');

    /* eslint-disable no-param-reassign */
    checkboxes.forEach((chck) => {
      chck.checked = event.target.checked;
    });
    /* eslint-enable no-param-reassign */

    let numberOfSelected;
    if (!event.target.checked) {
      numberOfSelected = 0;
    } else {
      numberOfSelected = checkboxes.length;
    }

    this.updateNumberOfSelected(numberOfSelected);
    this.toggleBulkActions(!event.target.checked);
    this.toggleAlternativeHeaderActions(event.target.checked);
  }

  toggleBulkActions = (hide = false) => {
    if (hide) {
      this.bulkActionsTarget.classList.add('d-none');
    } else {
      this.bulkActionsTarget.classList.remove('d-none');
    }
  }

  toggleAlternativeHeaderActions = (hide = false) => {
    if (hide) {
      document.getElementById('header-actions').classList.add('d-none');
      document.getElementById('secondary-header-actions').classList.add('d-none');
    } else {
      document.getElementById('header-actions').classList.remove('d-none');
      document.getElementById('secondary-header-actions').classList.remove('d-none');
    }
  }

  stockItemToggled = () => {
    const numberOfSelected = this.getNumberOfSelected();

    this.selectAllItemsTarget.checked = false;
    if (numberOfSelected === 0) {
      this.toggleBulkActions(true);
      this.toggleAlternativeHeaderActions(false);
    } else {
      this.toggleBulkActions();
      this.toggleAlternativeHeaderActions(true);
      this.updateNumberOfSelected(numberOfSelected);
    }
  }

  updateNumberOfSelected = (number) => {
    document.getElementById('number-ofselected').innerHTML = `${number} selected items`;
  }

  getNumberOfSelected = () => {
    const checkboxes = document.getElementsByName('select-stock-item');
    let numberOfSelected = 0;

    checkboxes.forEach((chck) => {
      if (chck.checked) {
        numberOfSelected += 1;
      }
    });

    return numberOfSelected;
  }

  cancelButton = () => {
    const checkboxes = document.getElementsByName('select-stock-item');
    /* eslint-disable no-param-reassign */
    checkboxes.forEach((chck) => {
      chck.checked = false;
    });
    /* eslint-enable no-param-reassign */

    this.selectAllItemsTarget.checked = false;
    this.toggleBulkActions(true);
  }

  selectedStockItemsData = () => {
    const checkboxes = document.getElementsByName('select-stock-item');
    let data = '';

    checkboxes.forEach((chck, index) => {
      if (chck.checked) {
        data += `stock_requirements[${index}][guid]=${chck.dataset.stockItemGuid}&`;
      }
    });

    return data;
  }

  bulkDeleteRequest = (event) => {
    event.preventDefault();
    Rails.ajax({
      type: 'DELETE',
      url: event.target.dataset.bulkDeleteStockItemsPath,
      data: this.selectedStockItemsData(),
    });
  }

  bulkHideRequest = (event) => {
    event.preventDefault();
    Rails.ajax({
      type: 'PUT',
      url: event.target.dataset.bulkHideStockItemsPath,
      data: this.selectedStockItemsData(),
    });
  }

  bulkToggleFeeRequest = (event) => {
    event.preventDefault();
    Rails.ajax({
      type: 'PUT',
      url: event.target.dataset.bulkToggleFeeToStockItemsPath,
      data: this.selectedStockItemsData(),
    });
  }

  createPoRequest = (event) => {
    event.preventDefault();
    const checkboxes = document.getElementsByName('select-stock-item');
    checkboxes.forEach((chck, index) => {
      if (chck.checked) {
        const quantity = document.getElementById(`${chck.dataset.stockItemGuid}-quantity`).value;
        const name = document.getElementById(`${chck.dataset.stockItemGuid}-name`).value;
        this.createHiddenInput(index, 'guid', chck.dataset.stockItemGuid);
        this.createHiddenInput(index, 'quantity', quantity);
        this.createHiddenInput(index, 'name', name);
        this.createHiddenInput(index, 'rate', 0);
        this.createHiddenInput(index, 'total', 0);
      }
    });

    Rails.fire(event.currentTarget.form, 'submit');
  }

  createHiddenInput = (index, fieldName, fieldValue) => {
    $('<input />').attr('type', 'hidden').attr('name', `purchase_order[line_items_attributes][${index}][${fieldName}]`).attr('value', fieldValue)
      .appendTo(this.createPoFormTarget);
  }
}
