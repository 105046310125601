import { Controller } from 'stimulus';
import moment from 'moment';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['reportDateRange'];

  connect() {
    const { remote, filters } = this.element.dataset;

    if (remote === 'true') {
      this.remote = true;
      this.currentFilters = JSON.parse(filters);
    }

    this.dateRange = flatpickr(this.reportDateRangeTarget, {
      mode: 'range',
      placeholder: 'Please select a date range',
      onChange: () => this.updatedDateRange(),
    });
  }

  currentUrlSearchParams() {
    if (this.remote) {
      return new URLSearchParams(this.currentFilters);
    }

    return new URLSearchParams(window.location.search);
  }

  updatedDateRange() {
    const {
      selectedDates: [startDate, endDate],
    } = this.dateRange;
    if (!startDate || !endDate) {
      return;
    }

    const urlParams = this.currentUrlSearchParams();
    urlParams.set('start_date', moment(startDate).format());
    urlParams.set('end_date', moment(endDate).format());

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  hideQuotesToggled(event) {
    const { checked } = event.target;
    const urlParams = this.currentUrlSearchParams();
    urlParams.set('hide_quotes', checked);

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  applyURLParams(urlParams) {
    if (!this.remote) {
      window.location.search = urlParams;
      return;
    }

    Rails.ajax({
      type: 'GET',
      url: `/agent/reports/whats_happening?${urlParams.toString()}`,
      dataType: 'script',
    });
  }
}
