import { Controller } from 'stimulus';
import $ from 'jquery';
import moment from 'moment';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['reportDateRange', 'invoicesTable', 'firmedBookingsTable'];

  connect() {
    const { remote, filters } = this.element.dataset;

    if (remote === 'true') {
      this.remote = true;
      this.currentFilters = JSON.parse(filters);
    }

    this.dateRange = flatpickr(this.reportDateRangeTarget, {
      allowInput: true,
      mode: 'range',
      placeholder: 'Please select a date range',
      onChange: () => this.updatedDateRange(),
    });

    const dataTableConfig = {
      searching: false,
      conditionalPaging: true,
      conditionalPageLength: true,
      dom:
        "<'row'<'col-12 table-responsive text-nowrap'tr>>" +
        "<'d-flex justify-content-end'<'pt-3'p><'pt-3'l>>",
      language: {
        lengthMenu: `
        <div class="form-inline">
          <div class="input-group ml-2">
            <div class="input-group-prepend">
              <div class="input-group-text" for="page-size-select">
                Items per page
              </div>
            </div>
            <select name="page_size" id="page-size-select" class="custom-select">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        `,
      },
    };

    if (this.hasInvoicesTableTarget) {
      $(this.invoicesTableTarget).DataTable(dataTableConfig);
    }
    if (this.hasFirmedBookingsTableTarget) {
      const firmedOptions = Object.assign(dataTableConfig, {
        order: [[1, 'asc']],
      });
      $(this.firmedBookingsTableTarget).DataTable(firmedOptions);
    }
  }

  currentUrlSearchParams() {
    if (this.remote) {
      return new URLSearchParams(this.currentFilters);
    }

    return new URLSearchParams(window.location.search);
  }

  updatedDateRange() {
    const {
      selectedDates: [startDate, endDate],
    } = this.dateRange;
    if (!startDate || !endDate) {
      return;
    }

    const urlParams = this.currentUrlSearchParams();
    urlParams.set('start_date', moment(startDate).format());
    urlParams.set('end_date', moment(endDate).format());
    // Delete the currently set date range with the select picker
    urlParams.delete('date_range');

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  predefinedDateRangeSelected(e) {
    const dateRange = e.currentTarget.value;

    const urlParams = this.currentUrlSearchParams();
    // Delete the currently set dates with the calendar picker
    urlParams.delete('start_date');
    urlParams.delete('end_date');

    // Display the results starting from the first page.
    urlParams.delete('page');

    if (dateRange) {
      urlParams.set('date_range', dateRange);
    }

    this.applyURLParams(urlParams);
  }

  applyURLParams(urlParams) {
    if (!this.remote) {
      window.location.search = urlParams;
      return;
    }

    Rails.ajax({
      type: 'GET',
      url: `/agent/reports/invoices?${urlParams.toString()}`,
      dataType: 'script',
    });
  }
}
