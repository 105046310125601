import { Controller } from 'stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = ['reloadPanel', 'reloadSpinner'];

  connect() {
    this.reloadUrl = this.element.dataset.reloadUrl;
    this.timer = setTimeout(this.reloadPanel.bind(this), 10000);
  }

  disconnect() {
    clearTimeout(this.timer);
  }

  async reloadPanel() {
    if (this.hasReloadSpinnerTarget) {
      this.reloadSpinnerTarget.style.opacity = '1';
    }
    const r = await axios.get(this.reloadUrl);
    if (r.status === 200) {
      const panel = this.hasReloadPanelTarget
        ? this.reloadPanelTarget
        : this.element;
      panel.innerHTML = r.data;
      if (this.hasReloadSpinnerTarget) {
        setTimeout(() => (this.reloadSpinnerTarget.style.opacity = '0'), 1000);
      }
      this.timer = setTimeout(this.reloadPanel.bind(this), 10000);
    } else {
      console.error('Failed to retrieve messages for purchase order');
    }
  }
}
