import { Controller } from 'stimulus';
import $ from 'jquery';
import moment from 'moment';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['calendarDateRange', 'stockFilter'];

  connect() {
    const { remote, filters } = this.element.dataset;

    if (remote === 'true') {
      this.remote = true;
      this.currentFilters = JSON.parse(filters);
    }

    $(this.stockFilterTarget).on('select2:select', () => {
      this.updateStockFilter();
    });
    $(this.stockFilterTarget).on('select2:clear', () => {
      this.updateStockFilter(true);
    });

    this.dateRange = flatpickr(this.calendarDateRangeTarget, {
      allowInput: true,
      mode: 'range',
      onChange: () => this.updatedDateRange(),
    });
  }

  disconnect() {
    $(this.stockFilterTarget).off('select2:select');
    $(this.stockFilterTarget).off('select2:clear');
  }

  currentUrlSearchParams() {
    if (this.remote) {
      return new URLSearchParams(this.currentFilters);
    }

    return new URLSearchParams(window.location.search);
  }

  updatedDateRange() {
    const {
      selectedDates: [startDate, endDate],
    } = this.dateRange;
    if (!startDate || !endDate) {
      return;
    }

    const urlParams = this.currentUrlSearchParams();
    urlParams.set('start_date', moment(startDate).format());
    urlParams.set('end_date', moment(endDate).format());

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  updateStockFilter(forceClear = false) {
    const filterData = $(this.stockFilterTarget).select2('data');
    const stockFilter = filterData.length > 0 ? filterData[0].id : null;

    const urlParams = this.currentUrlSearchParams();

    if (stockFilter) {
      urlParams.set('stock_filter', stockFilter);
    }

    if (forceClear) {
      urlParams.delete('stock_filter');
    }

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  controlsUpdated(event) {
    const { name, value } = event.target;

    const urlParams = this.currentUrlSearchParams();
    urlParams.set(name, value);

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  allStockSelected(event) {
    event.preventDefault();

    const urlParams = this.currentUrlSearchParams();
    urlParams.set('stock_filter', 'all_stock');

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  applyURLParams(urlParams) {
    if (!this.remote) {
      window.location.search = urlParams;
      return;
    }

    Rails.ajax({
      type: 'GET',
      url: `/agent/quarantine_transfers/calendar?${urlParams.toString()}`,
      dataType: 'script',
    });
  }
}
