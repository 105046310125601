import { Controller } from 'stimulus';
import { FEET_TO_METERS_MULTIPLIER } from '../../utils/sizeUnits';

export default class extends Controller {
  static targets = ['feetInput', 'metersInput'];

  connect() {
    this.metersInputTarget.value = (
      this.feetInputTarget.value * FEET_TO_METERS_MULTIPLIER
    ).toFixed(2);
    // Synchronize the mask with the current value.
    this.metersInputTarget.mask.updateValue();

    // Fire the change event so that other listeners on this value can react.
    this.metersInputTarget.dispatchEvent(new Event('change'));
  }

  metersValueChanged(event) {
    this.feetInputTarget.value = (
      event.target.value / FEET_TO_METERS_MULTIPLIER
    ).toFixed(2);

    // Fire the change event so that other listeners on this value can react.
    this.feetInputTarget.dispatchEvent(new Event('change'));
  }
}
