import BaseSpreadsheetController from './base_spreadsheet_controller';

export default class extends BaseSpreadsheetController {
  connect() {
    this.marquees = JSON.parse(this.element.dataset.marquees);
    this.stockTypeName = this.element.dataset.stockTypeName;
    super.connect();
  }

  editableColumns = () => [
    {
      title: `Select tent(s) to add ${this.stockTypeName.toLowerCase()} to <span class="text-danger">*</span>`,
      name: 'available_for_marquee_ids',
      width: 400,
      type: 'dropdown',
      source: this.marquees,
      multiple: true,
      autocomplete: true,
      wordWrap: true,
    },
    {
      title: `${this.stockTypeName} product name <span class="text-danger">*</span>`,
      name: 'name',
      width: 300,
      wordWrap: true,
    },
    {
      title: 'Starting price <span class="text-danger">*</span>',
      name: 'rate',
      width: 85,
      type: 'numeric',
      align: 'right',
      mask: '0.00',
    },
    {
      title: 'Additional section price <span class="text-danger">*</span>',
      name: 'additional_section_price',
      width: 85,
      type: 'numeric',
      align: 'right',
      mask: '0.00',
    },
  ];

  prepareDataForSubmission = () => ({
    stock: this.sheetTarget.jspreadsheet
      .getJson()
      .map(
        ({
          id,
          available_for_marquee_ids,
          name,
          rate,
          additional_section_price,
        }) => {
          let availableForMarqueesIds = available_for_marquee_ids;

          // This can either be an array of ids, or a string separated by ;, go figure...
          if (!Array.isArray(availableForMarqueesIds)) {
            availableForMarqueesIds = availableForMarqueesIds
              .split(';')
              .filter((marqueeId) => !!marqueeId);
          }

          return {
            id,
            name,
            rate,
            details: {
              additional_section_price,
            },
            available_for_marquees_attributes: availableForMarqueesIds.map(
              (marqueeId) => ({ marquee_stock_id: marqueeId })
            ),
          };
        }
      ),
  });
}
