import Element from './element';
import ElementFinder from './element_finder';
import DataBuilder from './data_builder';

export default class ClickTarget {
  constructor(e) {
    this.e = e;
    this.target = null;
    this.originalTargetHtml = null;
    this.options = {};
    this.initialize();
  }

  url() {
    return this.target.dataset.url;
  }

  method() {
    return this.target.dataset.method || 'get';
  }

  data(options) {
    let builder = new DataBuilder(this.target.dataset.formData);
    const fields = this.target.dataset.field || this.target.dataset.fields;

    if (fields) {
      const inputNames = fields[0] == '[' ? eval(fields) : [fields];

      for (const inputName of inputNames) {
        let inputValue = '';
        if (
          this.target.nodeName == 'INPUT' ||
          this.target.nodeName == 'SELECT'
        ) {
          inputValue = Element.value(this.target);
        } else {
          const parent =
            ElementFinder.find(this.target.dataset.fieldContainer) ||
            this.target.closest('.form-group') ||
            this.target.closest('.form-row');
          const inputElement = ElementFinder.find(inputName, parent);
          inputValue = Element.value(inputElement);
        }

        if (inputNames.length == 1) {
          builder.appendPair(inputName, inputValue);
          builder.appendPair('field', inputName);
        } else {
          builder.appendPair(`fields[${inputName}]`, inputValue);
        }
      }
    }

    if (options && options['asObject']) return builder.asObject();
    else return builder.asString();
  }

  openTabUrl() {
    return this.target.dataset.openTabUrl;
  }

  form() {
    let form = this.target.parentElement;
    if (form && form.nodeName == 'FORM') {
      this.appendAdditionalInputsToForm(form);
      return form;
    }

    if (this.options.form) {
      form = ElementFinder.find(this.options.form);
    } else {
      form = this.target.closest('form');
      if (
        !form &&
        this.target.parentElement.classList.contains('modal-footer')
      ) {
        form = this.target.parentElement.parentElement.querySelector('form');
      }
    }

    this.appendAdditionalInputsToForm(form);
    return form;
  }

  appendAdditionalInputsToForm(form) {
    if (!this.target.dataset.formData) {
      return;
    }
    const additionalInputs = this.data({ asObject: true });
    Object.keys(additionalInputs).forEach((name) => {
      Element.append(
        form,
        `<input type="hidden" name="${name}" value="${additionalInputs[name]}" />`
      );
    });
  }

  disableNoSpinner() {
    this.target.disabled = true;
    this.target.readOnly = true;
  }

  disable() {
    this.disableNoSpinner();
    let innerElement = this.target.children[0];

    if (Element.isSelect(this.target)) return;

    if (this.options.fadeButtonNoSpinner) {
      this.target.style.opacity = 0.2;
      return;
    }

    if (
      innerElement &&
      innerElement.classList.length > 0 &&
      innerElement.nodeName != 'svg'
    ) {
      let additionalClasses = Array.from(innerElement.classList.values()).join(
        ' '
      );
      this.target.innerHTML = `<div class="${additionalClasses}">${this.spinnerHtml()}</div>`;
    } else {
      this.target.innerHTML = this.spinnerHtml();
    }
  }

  enableNoSpinner() {
    this.target.disabled = false;
    this.target.readOnly = false;
  }

  enable() {
    this.enableNoSpinner();
    if (this.originalTargetHtml) {
      this.target.innerHTML = this.originalTargetHtml;
    }
  }

  initialize() {
    let target = null;
    let isEvent = this.e.constructor.name.indexOf('Event') != -1;
    if (isEvent) target = this.e.target;
    else target = this.e;

    if (!this.isClickTarget(target) && target.parentElement) {
      target = target.parentElement;
    }

    if (!this.isClickTarget(target)) {
      const possibleTarget =
        target.closest('BUTTON') ||
        target.closest('INPUT') ||
        target.closest('SELECT') ||
        target.closest('A');
      if (possibleTarget)
        target = possibleTarget;
    }

    if (!this.isClickTarget(target)) {
      const possibleTarget = ElementFinder.find('BUTTON', target) ||
                              ElementFinder.find('INPUT', target) ||
                              ElementFinder.find('BUTTON', target) ||
                              ElementFinder.find('A', target);
      if (possibleTarget)
        target = possibleTarget;
    }

    if (!this.isClickTarget(target)) {
      console.log(
        `Cannot find click target, original target was ${
          this.e.target.nodeName
        }/${this.e.target.id || 'no id'}`
      );
    } else {
      this.target = target;
      this.originalTargetHtml = this.target.innerHTML;
      this.options = {
        refocus: target.dataset.refocus == 'true' ? true : false,
        contentTarget: target.dataset.contentTarget,
        noSpinner: target.dataset.noSpinner == 'true' ? true : false,
        fadeButtonNoSpinner:
          target.dataset.fadeButtonNoSpinner == 'true' ? true : false,
        doNotDisableButton:
          target.dataset.doNotDisableButton == 'true' ? true : false,
        form: target.dataset.form,
        confirm: target.dataset.confirm,
        field: target.dataset.field,
        outer: target.dataset.outer == 'true' ? true : false,
      };
    }
  }

  isClickTarget(el) {
    return (
      el &&
      (el.nodeName == 'BUTTON' ||
        el.nodeName == 'INPUT' ||
        Element.isSelect(el) ||
        el.nodeName == 'A')
    );
  }

  spinnerHtml() {
    if (this.options.noSpinner) return `${this.target.dataset.text || ''}`;
    else
      return `<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>${
        this.target.dataset.text || ''
      }`;
  }
}
