import { Controller } from 'stimulus';
import ButtonClickRequest from '../../helpers/button_click_request';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

export default class extends Controller {
  connect() {
    const app = this;
    this.lastValue = null;
    flatpickr(this.element, {
      wrap: true,
      allowInput: true,
      onChange: (selectedDates, dateStr, instance) => {
        // Only track the due date to display the warning if this is a persisted invoice
        if (this.element.dataset.dueDateWarningEnabled === 'true') {
          if (selectedDates.length > 0) {
            this.clearDueDateWarning();
          } else {
            this.setDueDateWarning();
          }
        }
        app.onChange(selectedDates, dateStr, instance); 
      },
    });
  }

  clearDueDateWarning() {
    // Clear the picker warning when the user manually changes the due date by
    // interacting with the controls.
    const inputElement = this.element.querySelector('input.flatpickr-input');
    inputElement.placeholder = '';
    inputElement.style.backgroundColor = '';
  }

  setDueDateWarning() {
    // Put back the warning if the selected due date is cleared.
    const inputElement = this.element.querySelector('input.flatpickr-input');
    inputElement.placeholder = 'Please set a due date';
    inputElement.style.backgroundColor = '#fddad8';
  }

  onChange(selectedDates, dateStr, instance) {
    if (this.element.dataset.sendHttpUpdate != 'true' || this.lastValue == dateStr) {
      return;
    }
    this.lastValue = dateStr;    
    new ButtonClickRequest(this.element).execute();
  }

}
