import { Controller } from 'stimulus';
import moment from 'moment';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

export default class extends Controller {
  static targets = ['dateRange', 'plannerCardsContainer', 'bookingResourcesContainer', 'staffAndVehiclesContainer'];

  connect() {
    const { startDate, endDate } = this.element.dataset;

    this.dateRange = flatpickr(this.dateRangeTarget, {
      allowInput: true,
      mode: 'range',
      defaultDate: [startDate, endDate],
      placeholder: 'Please select a date range',
      onChange: (selectedDates) => {
        if (selectedDates.length <= 1) return;
        this.updateUrlParamsAndRedirect();
      },
    });
  }

  updateUrlParamsAndRedirect() {
    const urlParams = new URLSearchParams(window.location.search);

    if (this.dateRange.selectedDates.length === 2) {
      urlParams.set('start_date', moment(this.dateRange.selectedDates[0]).format());
      urlParams.set('end_date', moment(this.dateRange.selectedDates[1]).format());
      urlParams.delete('date_range');
    }

    window.location.search = urlParams;
  }

  pageZoomSelected(event) {
    const zoomLevel = Number(event.currentTarget.value || 1);
    if (this.hasPlannerCardsContainerTarget) {
      this.setZoomLevel(this.plannerCardsContainerTarget, zoomLevel);
    } else if (this.hasStaffAndVehiclesContainerTarget) {
      this.setZoomLevel(this.staffAndVehiclesContainerTarget, zoomLevel);
    } else {
      this.setZoomLevel(this.bookingResourcesContainerTarget, zoomLevel);
    }
  }

  setZoomLevel = (element, zoomLevel) => {
    const targetElement = element;

    targetElement.style.zoom = zoomLevel;
    targetElement.style['-ms-zoom'] = zoomLevel;
    targetElement.style['-webkit-zoom'] = zoomLevel;
    targetElement.style['-moz-transform'] = `scale(${zoomLevel})`;
    targetElement.style['-moz-transform-origin'] = '0 0';
  }

  predefinedDateRangeSelected = (e) => {
    const dateRange = e.currentTarget.value;

    const urlParams = new URLSearchParams(window.location.search);
    // Delete the currently set dates with the calendar picker
    urlParams.delete('start_date');
    urlParams.delete('end_date');

    // Display the results starting from the first page.
    urlParams.delete('page');

    if (dateRange) {
      urlParams.set('date_range', dateRange);
    }

    window.location.search = urlParams;
  }

  reloadPage = () => {
    window.location.reload();
  }
}
