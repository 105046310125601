import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', this.toggleStockRules.bind(this));
  }

  toggleStockRules() {
    const showRules = this.element.checked;
    const url = new URL(window.location);
    if (showRules) {
      url.searchParams.set('show_stock_rules', 'true');
    } else {
      url.searchParams.delete('show_stock_rules');
    }
    window.location = url.href;
  }
}
