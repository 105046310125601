import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const selectStockType = document.getElementById('stock_library_stock_type');
    this.toggleTableFields(selectStockType);
    selectStockType.addEventListener('change', (event) => this.toggleTableFields(event.target));
  }

  toggleTableFields = (selectStockType) => {
    const detailsElement = document.getElementById('table-additional-details');
    const { value } = selectStockType.options[selectStockType.selectedIndex];

    if (value === 'Table') {
      detailsElement.style = '';
    } else {
      detailsElement.style = 'display: none;';
    }
  }
}
