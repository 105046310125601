import BaseSpreadsheetController from './base_spreadsheet_controller';

export default class extends BaseSpreadsheetController {
  editableColumns = () => [
    {
      title: 'Name <span class="text-danger">*</span>',
      name: 'name',
      width: 350,
      wordWrap: true,
    },
  ];

  prepareDataForSubmission = () => ({
    vehicles: this.sheetTarget.jspreadsheet.getJson(),
  });
}
