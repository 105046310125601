import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['rate', 'qty', 'total'];

  connect() {
    [this.rateTarget, this.qtyTarget].forEach((e) =>
      e.addEventListener('change', this.formUpdated.bind(this))
    );
  }

  formUpdated() {
    // when the quantity or rate is updated, update the total
    const rate = this.rateTarget.mask.unmaskedValue;
    const quantity = this.qtyTarget.value;
    this.totalTarget.value = (rate * quantity).toFixed(2);

    const e = new Event('change');
    this.totalTarget.dispatchEvent(e);
  }
}
