import $ from 'jquery';
import Rails from '@rails/ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['currentTaxes', 'taxesSelect', 'submitButton', 'addCustomTaxCheckbox',
    'nameInput', 'descriptionInput', 'rateInput', 'addCustomTaxButton'];

  connect() {
    const taxesSearchPath = this.data.get('taxes-search-path');
    const prompt = this.data.has('prompt') ? this.data.get('prompt') : 'Find taxes...';
    $(this.taxesSelectTarget).select2({
      placeholder: prompt,
      allowClear: true,
      minimumInputLength: 2,
      width: '100%',
      ajax: {
        url: taxesSearchPath,
        dataType: 'json',
      },
    });
  }

  updateTaxes(event) {
    event.preventDefault();

    const submitForm = () => Rails.fire(this.submitButtonTarget.form, 'submit');
    // wait for the modal to be dismissed completely before submitting the form
    $('#edit-taxes-modal').on('hidden.bs.modal', submitForm.bind(this));
    $('#edit-taxes-modal').modal('hide');

    this.updateSubmitButtonState(false);
  }

  addSelectedTax(event) {
    event.preventDefault();
    const taxGuid = $(this.taxesSelectTarget).select2('data')[0].id;

    this.addTax({ tax_guid: taxGuid });

    // Clear selection
    $(this.taxesSelectTarget).val(null).trigger('change');
  }

  customTaxToggled(event) {
    const { checked } = event.target;

    this.disableCustomTaxInputs(!checked);
  }

  addCustomTax() {
    this.addTax({
      name: this.nameInputTarget.value,
      description: this.descriptionInputTarget.value,
      rate: this.rateInputTarget.value / 100.0,
      custom: true,
    });

    this.nameInputTarget.value = '';
    this.descriptionInputTarget.value = '';
    this.rateInputTarget.value = 0;
    this.addCustomTaxCheckboxTarget.checked = false;
    this.disableCustomTaxInputs(true);
  }

  addTax(taxFields) {
    Rails.ajax({
      type: 'post',
      url: this.data.get('add-resource-tax-path'),
      dataType: 'script',
      data: new URLSearchParams({
        ...taxFields,
        position: this.currentTaxesTarget.childElementCount,
      }).toString(),
    });

    this.updateSubmitButtonState(true);
  }

  removeTax(event) {
    event.preventDefault();
    $(event.target).parents('tr')[0].classList.add('d-none');
    $(event.currentTarget).siblings('input[type=hidden]')[0].value = true;

    this.updateSubmitButtonState(true);
  }

  taxRateChanged() {
    this.updateSubmitButtonState(true);
  }

  updateSubmitButtonState(enabled) {
    this.submitButtonTarget.disabled = !enabled;
  }

  disableCustomTaxInputs(disabled) {
    this.nameInputTarget.disabled = disabled;
    this.descriptionInputTarget.disabled = disabled;
    this.rateInputTarget.disabled = disabled;
    this.addCustomTaxButtonTarget.disabled = disabled;
  }
}
