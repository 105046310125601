import { Controller } from 'stimulus';
import $ from 'jquery';
import SignaturePad from 'signature_pad';

export default class extends Controller {
  static targets = [
    'signatureCanvas',
    'signatureFileInput',
    'signatureErrorMessage',
  ];

  connect() {
    this.signaturePad = new SignaturePad(this.signatureCanvasTarget);

    // Keep the file to be submitted as the signature in sync with the signature pad.
    this.signaturePad.addEventListener('endStroke', () =>
      this.updateSignatureFileInput()
    );

    $(this.element).on('shown.bs.modal', () => {
      this.resizeCanvas();
      window.onresize = () => this.resizeCanvas();
    });

    $(this.element).on('hidden.bs.modal', () => {
      window.onresize = null;
    });
  }

  resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const canvas = this.signatureCanvasTarget;

    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
  }

  clearCanvas() {
    this.signaturePad.clear();
  }

  async updateSignatureFileInput() {
    if (this.signaturePad.isEmpty()) {
      return;
    }

    // Hide the error about the signature being empty, if visible.
    this.hideEmptySignatureErrorMessage();

    // Takes in the canvas as a PNG blob, converts it into a temporary data transfer that can then
    // be assigned to the file input in order to be submitted as a regular file.
    const url = this.signaturePad.toDataURL();
    const result = await fetch(url);
    const blob = await result.blob();
    const file = new File([blob], 'signature.png', { type: 'image/png' });

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    this.signatureFileInputTarget.files = dataTransfer.files;
  }

  submitForm(event) {
    if (this.signaturePad.isEmpty()) {
      event.preventDefault();
      this.showEmptySignatureErrorMessage();
    } else {
      this.hideEmptySignatureErrorMessage();
    }
  }

  showEmptySignatureErrorMessage() {
    this.signatureErrorMessageTarget.classList.remove('d-none');
    this.signatureErrorMessageTarget.classList.add('d-flex');
  }

  hideEmptySignatureErrorMessage() {
    this.signatureErrorMessageTarget.classList.remove('d-flex');
    this.signatureErrorMessageTarget.classList.add('d-none');
  }

  closeModal() {
    $(this.element).modal('hide');
  }
}
