import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.searchParamName = this.element.dataset.searchParamName;
    this.element.addEventListener('change', this.switchToggled.bind(this));
  }

  switchToggled() {
    const { checked: switchChecked } = this.element;
    const url = new URL(window.location);
    if (switchChecked) {
      url.searchParams.set(this.searchParamName, 'true');
    } else {
      url.searchParams.delete(this.searchParamName);
    }
    window.location = url.href;
  }
}
