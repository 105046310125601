import BaseSpreadsheetController from './base_spreadsheet_controller';

export default class extends BaseSpreadsheetController {
  connect() {
    this.stockLibraries = JSON.parse(this.element.dataset.stockLibraries);
    super.connect();
  }

  editableColumns = () => [
    {
      title: 'Select structure type <span class="text-danger">*</span>',
      name: 'stock_library_id',
      width: 175,
      type: 'dropdown',
      source: this.stockLibraries,
    },
    {
      title: 'Name of structure <span class="text-danger">*</span>',
      name: 'name',
      width: 425,
      wordWrap: true,
    },
    {
      title: 'Starter width (m) <span class="text-danger">*</span>',
      name: 'starting_marquee_width',
      width: 75,
      type: 'numeric',
      align: 'right',
      mask: '0.00',
    },
    {
      title: 'Starter length (m) <span class="text-danger">*</span>',
      name: 'starting_marquee_length',
      width: 75,
      type: 'numeric',
      align: 'right',
      mask: '0.00',
    },
    {
      title: 'Additional length (m) <span class="text-danger">*</span>',
      name: 'additional_marquee_length',
      width: 85,
      type: 'numeric',
      align: 'right',
      mask: '0.00',
    },
    {
      title: 'Starting price <span class="text-danger">*</span>',
      name: 'rate',
      width: 85,
      type: 'numeric',
      align: 'right',
      mask: '0.00',
    },
    {
      title: 'Additional section price <span class="text-danger">*</span>',
      name: 'additional_marquee_price',
      width: 85,
      type: 'numeric',
      align: 'right',
      mask: '0.00',
    },
  ];

  prepareDataForSubmission = () => ({
    stock: this.sheetTarget.jspreadsheet
      .getJson()
      .map(
        ({
          id,
          stock_library_id,
          name,
          rate,
          starting_marquee_width,
          starting_marquee_length,
          additional_marquee_length,
          additional_marquee_price,
        }) => ({
          id,
          stock_library_id,
          name,
          rate,
          details: {
            starting_marquee_width,
            starting_marquee_length,
            additional_marquee_length,
            additional_marquee_price,
          },
        })
      ),
  });
}
