import ClickTarget from './click_target';
import FormRequest from './form_request';

export default class FileUploadRequest {
  constructor(e) {
    this.e = e;
    this.button = new ClickTarget(e);
    this.form = this.button.form();
  }

  chooseFileAndUpload() {
    this.e.preventDefault();
    if (this.form.reportValidity()) {
      let fileInput = this.e.target.parentElement.querySelector('input[type=file]');
      if (!fileInput) {
        fileInput = this.form.querySelector('input[type=file]');
      }
      const fileChangeListener = (e) => {
        new FormRequest(this.e).execute();
      };
      fileInput.addEventListener('change', fileChangeListener);
      fileInput.click();
    }
  }
}
