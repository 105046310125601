import $ from 'jquery';
import Rails from '@rails/ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['rate', 'quantity', 'total', 'modal'];

  connect() {
    this.costTypeData = JSON.parse(this.element.dataset.costTypes);
    this.rateTarget.addEventListener('change', this.updateTotal.bind(this));
    this.quantityTarget.addEventListener('change', this.updateTotal.bind(this));
  }

  updateTotal() {
    if (this.rateTarget.value && this.quantityTarget.value) {
      this.totalTarget.value = this.rateTarget.value * this.quantityTarget.value;
    }
  }

  costTypeUpdated({ currentTarget: { value: costTypeName } }) {
    const costType = this.costTypeData.find((ct) => ct.name === costTypeName);
    if (costType.rate !== null) {
      this.rateTarget.value = costType.rate;
      this.totalTarget.value = costType.rate;
    }
    this.quantityTarget.value = 1;
  }

  submit(e) {
    e.preventDefault();
    const valid = e.currentTarget.form.checkValidity();
    if (valid) {
      Rails.fire(e.currentTarget.form, 'submit');
      $('#add-line-item-modal').modal('hide');
    }
  }
}
