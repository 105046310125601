import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['editor', 'addButton', 'destroyInput'];

  handleAddFieldClick() {
    this.destroyInputTarget.value = false;
    this.editorTarget.classList.remove('d-none');
    this.addButtonTarget.classList.add('d-none');
  }

  handleRemoveFieldClick() {
    this.destroyInputTarget.value = true;
    this.editorTarget.classList.add('d-none');
    this.addButtonTarget.classList.remove('d-none');
  }
}
