import { Controller } from 'stimulus';
import $ from 'jquery';
import Rails from '@rails/ujs';
import _ from 'lodash';

const debounceTimeout = 200;

export default class extends Controller {
  static targets = ['searchIcon', 'searchInput'];

  connect() {
    $('#multi-search-modal')
      .on('shown.bs.modal', () => this.focusSearchInput())
      .on('hidden.bs.modal', () => {
        // Clear any previous search/results
        this.clearSearchInput();
        this.updateSearchResults();
      });

    this.debouncedSearch = _.debounce(this.search, debounceTimeout);
  }

  // eslint-disable-next-line class-methods-use-this
  disconnect() {
    $('#multi-search-modal').off('shown.bs.modal');
    $('#multi-search-modal').off('hidden.bs.modal');
  }

  // eslint-disable-next-line class-methods-use-this
  showModal(event) {
    event.preventDefault();

    $('#multi-search-modal').modal('show');
  }

  updateSearchResults() {
    this.setLoading(true);
    this.debouncedSearch();
  }

  search() {
    Rails.fire(this.searchInputTarget.form, 'submit');
  }

  removeLoading() {
    this.setLoading(false);
  }

  setLoading(searching) {
    if (searching) {
      this.searchIconTarget.classList.remove('fa', 'fa-search');
      this.searchIconTarget.classList.add('fas', 'fa-spinner', 'fa-spin');
    } else {
      this.searchIconTarget.classList.remove('fas', 'fa-spinner', 'fa-spin');
      this.searchIconTarget.classList.add('fa', 'fa-search');
    }
  }

  clearSearchInput() {
    this.searchInputTarget.value = '';
  }

  focusSearchInput() {
    this.searchInputTarget.focus();
  }
}
