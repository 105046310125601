import { Controller } from 'stimulus';
import $ from 'jquery';
import Chart from 'chart.js';
import humps from 'humps';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { Paired12 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer';

const barChartOptions = ({ xAxisTitle, yAxisTitle, title, displayLegend }) => ({
  plugins: {
    colorschemes: {
      scheme: Paired12,
    },
  },
  maintainAspectRatio: false,
  title: {
    display: !!title,
    text: title,
  },
  legend: {
    display: displayLegend,
  },
  scales: {
    xAxes: [{
      display: true,
      scaleLabel: {
        display: true,
        labelString: xAxisTitle,
      },
      gridLines: {
        display: false,
      },
    }],
    yAxes: [{
      display: true,
      scaleLabel: {
        display: true,
        labelString: yAxisTitle,
      },
    }],
  },
});

export default class extends Controller {
  static targets = ['reportChart', 'reportTable', 'searchInput'];

  connect() {
    if (this.hasReportTableTarget) {
      this.initReportTable();
    }

    if (this.hasReportChartTarget) {
      this.initReportChart();
    }
  }

  initReportTable() {
    const dataTableConfig = {
      conditionalPaging: true,
      conditionalPageLength: true,
      order: [],
      dom: `
        <'row'
          <'col-12 table-responsive text-nowrap'tr>
        >
        <'row'
          <'col-12 d-flex justify-content-end'
            <'pt-3'p><'pt-3'l>
          >
        >
      `,
      language: {
        searchPlaceholder: 'Search report',
        search: '',
        lengthMenu: `
        <div class="form-inline">
          <div class="input-group ml-2">
            <div class="input-group-prepend">
              <div class="input-group-text" for="page-size-select">
                Items per page
              </div>
            </div>
            <select name="page_size" id="page-size-select" class="custom-select">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        `,
      },
    };

    this.dataTable = $(this.reportTableTarget).DataTable(dataTableConfig);
  }

  initReportChart() {
    const { dataset: { chartData } } = this.reportChartTarget;
    const parsedChartData = humps.camelizeKeys(JSON.parse(chartData));

    const multipleDatasets = parsedChartData.datasets.length > 1;
    const colouredDatasets = parsedChartData.datasets.map((dataset) => ({
      ...dataset,
      // if we're plotting multiple datasets, use a single colour for all of each dataset columns,
      // otherwise (single dataset) use a different colour for each column.
      backgroundColor: multipleDatasets ? undefined : Paired12,
    }));

    const data = {
      type: 'bar',
      data: {
        datasets: colouredDatasets,
        labels: parsedChartData.labels,
      },
      options: {
        ...barChartOptions({
          xAxisTitle: parsedChartData.xAxisTitle,
          yAxisTitle: parsedChartData.yAxisTitle,
          title: parsedChartData.chartTitle,
          // When using multiple colours for the same dataset, hide the legend, because it will
          // display the colour of the first column, which looks buggy.
          displayLegend: multipleDatasets,
        }),
      },
    };

    this.chart = new Chart(this.reportChartTarget, data);
  }

  updateSearchTerm(event) {
    this.dataTable.search(event.target.value).draw();
  }
}
