import axios from 'axios';
import toastr from 'toastr';
import sortable from 'html5sortable/dist/html5sortable.es';
import { Controller } from 'stimulus';

/* eslint class-methods-use-this:
  ["error", { "exceptMethods": ["connect"] }] */
export default class extends Controller {
  connect() {
    this.marqueeItemIndexes = JSON.parse(
      this.element.dataset.marqueeItemIndexes
    );
    sortable('tbody.tbody-sortable', {
      items: '.tr-sortable',
      acceptFrom: 'tbody.tbody-sortable',
      placeholder:
        '<tr class="table-primary"><td colspan="6"><span class="center">The row will appear here</span></td></tr>',
      forcePlaceholderSize: false,
    });
    sortable('tbody.tbody-sortable').forEach((list) => {
      list.addEventListener('sortupdate', this.doIt.bind(this));
    });
  }

  async doIt(e) {
    const { indexUpdatePath } = e.detail.item.dataset;
    const newIndex = e.detail.destination.elementIndex + 1;

    // if (this.marqueeItemIndexes.includes(newIndex)) {
    //   if (
    //     !window.confirm(
    //       'Are you sure you want to try and relocate to this marquee?'
    //     )
    //   ) {
    //     return;
    //   }
    // }
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');

    await fetch(indexUpdatePath, {
      method: 'PUT',
      body: JSON.stringify({
        new_index: newIndex,
      }),
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
        Accept: 'application/javascript',
      },
    });
  }
}
