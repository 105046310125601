import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    this.lazyLoadUrl = this.element.dataset.lazyLoadTargetUrl;
    $(this.element).on('shown.bs.tab', () => {
      if (!this.loaded) {
        this.loadTabContent();
      }
    });
  }

  loadTabContent() {
    Rails.ajax({
      type: 'GET',
      url: this.lazyLoadUrl,
      dataType: 'script',
      success: () => {
        this.loaded = true;
      },
    });
  }
}
