import { Controller } from 'stimulus';

export default class extends Controller {
  lineItemRemoved() {
    if (this.element.querySelectorAll('tbody tr').length > 0) {
      return;
    }
    this.element.remove();
  }
}
