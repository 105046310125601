import Chart from 'chart.js';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const { dataset: { chartData } } = this.element;
    this.parsedChartData = JSON.parse(chartData);
    const labels = Object.keys(this.parsedChartData);
    const chartArray = labels.map((label) => this.totalFor(label));
    const data = {
      type: 'doughnut',
      data: {
        labels,
        datasets: [{
          data: chartArray,
          backgroundColor: [
            'rgba(255, 99, 132, 0.5)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
          ],
          borderColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
          ],
        }],
      },
      options: {
        title: {
          display: false,
        },
      },
    };
    this.chart = new Chart(this.element, data);
  }

  totalFor(key) {
    if (!Object.prototype.hasOwnProperty.call(this.parsedChartData, key)) return 0;
    return this.parsedChartData[key]
      .map((c) => c.total)
      .reduce((a, b) => Number(a) + Number(b), 0);
  }
}
