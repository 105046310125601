import $ from 'jquery';
import '@hotwired/turbo-rails';

Turbo.session.drive = false;
import '../utils/turboStreams';

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('jquery');
require('bootstrap');
require('stimulus');
require('toastr');
require('datatables.net-bs4')(window, $);
require('datatables.net-plugins/features/conditionalPaging/dataTables.conditionalPaging');
require('datatables.net-plugins/features/conditionalPageLength/dataTables.conditionalPageLength');

/* eslint-disable import/first */
import '../theme/appstack_legacy/sidebar';
import '../theme/appstack_legacy/theme';
import '../controllers';
import setupAxiosHeaders from '../utils/setupAxiosHeaders';
import '../styles/offcanvas';

// setup axios for rails
setupAxiosHeaders();

require('trix');
require('@rails/actiontext');
