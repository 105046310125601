import Rails from '@rails/ujs';
import ClickTarget from '../../helpers/click_target';
import ButtonClickRequest from '../../helpers/button_click_request';
import FormRequest from '../../helpers/form_request';
import HttpRequest from '../../helpers/http_request';
import FileUploadRequest from '../../helpers/file_upload_request';
import ElementFinder from '../../helpers/element_finder';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'modal', 'modalContent' ];

  connect() {
    if (!this.hasModalTarget)
      return;

    const app = this;

    if (this.element.dataset.openModalOnConnect) {
      app.open({});
    } else {
      $(this.modalTarget).on('show.bs.modal', (e) => app.handleModalOpened(e.relatedTarget));
      $(this.modalTarget).on('hide.bs.modal', (e) => app.handleModalClosed(e));
    }

    this.onCloseCallback = null;
  }

  open({ e, modalTargetId, onCloseCallback }) {
    if (!e)
      e = { target: this.element };
    const modal = document.querySelector(modalTargetId || e.target.dataset.modalTargetId) || this.element;
    $(modal).modal();
    this.modal = modal;
    this.modalContent = modal.querySelector('.modal-dialog');
    this.handleModalOpened(e.target);
    this.onCloseCallback = onCloseCallback;
  }

  handleModalClosed(e) {
    const modalContent = e.target.querySelector('.modal-content');

    if (modalContent.dataset.onCloseReloadUrl == 'current_tab_or_url') {
      new HttpRequest(location.href, 'get', null, { contentTarget: modalContent.dataset.reloadContentTarget }).execute();
    }

    if (this.onCloseCallback) {
      this.onCloseCallback();
    }
  }

  handleModalOpened(target) {
    if (!target) return;

    if (target.dataset.text) {
      this.showMessage(target.dataset.text);
      return;
    }

    let url = this.buildUrl(target);
    if (!url) return;

    if (this.hasModalContentTarget) {
      this.showMessage('<p>Loading ...</p>');
    }

    const button = new ClickTarget(target);
    button.disableNoSpinner();

    if (target.dataset.form) {
      new FormRequest(target).execute(() => button.enableNoSpinner());
    } else {
      new HttpRequest(url, target.dataset.method || 'get', null, { contentTarget: this.modalContentTarget }).execute(() => {
        button.enableNoSpinner();
      });
    }
  }

  showMessage(message) {
    this.setModalBody(`<p>${message}</p>`);
  }

  setModalBody(html) {
    this.modalBody().innerHTML = html;
  }

  modalBody() {
    return (this.modal || this.modalTarget).querySelector('.modal-body');
  }

  click(e) {
    new ButtonClickRequest(e, this.modalContentTarget).execute((response) => {
      this.afterRequest(response);
    });
  }

  chooseFile(e) {
    new FileUploadRequest(e).chooseFile();
  }

  chooseFileAndUpload(e) {
    new FileUploadRequest(e).chooseFileAndUpload();
  }

  submit(e) {
    new FormRequest(e).execute((response) => {
      this.afterRequest(response);
    });
  }

  hide(){
    $(this.modalTarget).modal('hide');
  }

  afterRequest(response) {
    if (response.success) {
      const modalContent = this.modalTarget.querySelector('.modal-content');

      if (modalContent.dataset.closeModalOnSuccess) {
        this.hide();
      }

      if (modalContent.dataset.closeModalUsingResponse && response.close_modal) {
        this.hide();
      }
    }
  }

  buildUrl(target) {
    let url = target.dataset.url;
    const field = target.dataset.field;

    if (field) {
      let inputValue = '';
      const parent = target.closest('.input-group') || target.closest('.form-group');
      const inputElement = ElementFinder.find(field, parent);
      if (inputElement) {
        const keyPairs = {};
        keyPairs[field] = inputElement.value;
        keyPairs['field'] = field;
        url = UrlBuilder.build(url, keyPairs);
      }
    }

    return url;
  }
}
