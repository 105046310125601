import { Controller } from 'stimulus';
import { Calendar } from '@fullcalendar/core';
import multiMonthPlugin from '@fullcalendar/multimonth';
import bootstrapPlugin from '@fullcalendar/bootstrap';

export default class extends Controller {
  static targets = ['calendarContainer'];

  connect() {
    if (!this.element.dataset.eventItems)
      return;
    const eventItems = JSON.parse(this.element.dataset.eventItems);
    const calendarInitialDate = Date.parse(this.element.dataset.calendarDate);
    this.calendar = new Calendar(this.calendarContainerTarget, {
      plugins: [multiMonthPlugin, bootstrapPlugin],
      headerToolbar: {
        left: 'prev,next today',
        center: '',
        right: 'title',
      },
      initialDate: calendarInitialDate,
      initialView: 'multiMonthYear',
      multiMonthMaxColumns: 1,
      navLinks: true,
      events: eventItems,
      themeSystem: 'bootstrap',
      displayEventTime: false,
    });
    this.calendar.render();
  }

  monthUpdated(e) {
    const dateToGoTo = Date.parse(e.currentTarget.value);
    this.calendar.gotoDate(dateToGoTo);
  }
}
