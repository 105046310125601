import $ from 'jquery';
import 'select2/dist/js/select2.full';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['conditionStockItem', 'conditionAdd'];

  connect() {
    $(this.conditionAddTarget).prop('disabled', true);

    $(this.conditionStockItemTarget).on('select2:select', () => {
      $(this.conditionAddTarget).prop('disabled', false);
    });

    $(this.conditionStockItemTarget).on('select2:clear', () => {
      $(this.conditionAddTarget).prop('disabled', true);
    });
  }
}
