import toastr from 'toastr';
import Rails from '@rails/ujs';
import ContentRenderer from './content_renderer';
import ClickTarget from '../helpers/click_target';
import Value from '../helpers/value';

export default class FormRequest {
  constructor(e, contentTarget) {
    this.e = e;
    this.button = new ClickTarget(e);
    this.callback = null;
    this.options = this.button.options || {};
    if (!this.options.contentTarget && contentTarget) {
      this.options.contentTarget = contentTarget;
    }
  }

  execute(callback) {
    if (this.e.preventDefault)
      this.e.preventDefault();

    this.callback = callback;
    const form = this.button.form();

    if (!form || !form.reportValidity())
      return;

    if (!this.options.doNotDisableButton)
      this.button.disable();

    form.addEventListener('ajax:success', (e) => { this.onSuccess(e) });
    form.addEventListener('ajax:error', (e) => { this.onError(e) });

    Rails.fire(form, 'submit');
  }

  onSuccess(e) {
    let [data, status, xhr] = e.detail;

    this.reEnableButton();

    new ContentRenderer(xhr, (response) => { this.onContentRender(response) }, this .options.contentTarget).render();
  }

  onContentRender(response) {
    if (this.callback) {
      this.callback(response);
    }
  }

  onError(e) {
    let [data, status, xhr] = e.detail;
    this.reEnableButton();
    if (this.callback)
      this.callback({ success: false });

    if (data && data.message) {
      toastr.error(data.message);
    } else {
      toastr.error(`Unhandled error: ${Value.toString(data)}`);
    }
  }

  reEnableButton() {
    if (!this.options.doNotDisableButton)
      this.button.enable();
  }

}
