import $ from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.css';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['availableForMarqueesSelect'];

  connect() {
    $(this.availableForMarqueesSelectTarget).select2({
      placeholder: 'Select marquee',
      allowClear: true,
      width: '100%',
    });
  }
}
