import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.template = this.element.dataset.t;
  }

  addRequestItem(e) {
    const addItemContainer = e.currentTarget.closest('.form-group');
    const newItemForm = document
      .createRange()
      .createContextualFragment(this.template);
    addItemContainer.before(newItemForm);
    e.preventDefault();
  }

  deleteRequestItem(e) {
    e.currentTarget.closest('.request-item-form').remove();
    e.preventDefault();
  }
}
