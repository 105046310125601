import { Controller } from 'stimulus';
import $ from 'jquery';

// The delay before scrolling into the current step, to allow page to render properly beforehand.
// Tried to do it after the dom is ready via $(() => {}) but it didn't work properly.
const SCROLL_TO_STEP_DELAY_MS = 1000;

const showStep = (step) => {
  $(step).find('.step-overlay').remove();
  $(step).find(':input:enabled:visible').removeAttr('tabindex');
  $(step).find(':input:enabled:visible:first').trigger('focus');
};

const scrollToStep = (step) => {
  // A % offset matching the margin top applied to center the first step vertically on the page.
  const offset = window.innerHeight * 0.3;
  $('html, body').animate({
    scrollTop: $(step).offset().top - offset,
  }, 400);
};

const hideStep = (step) => {
  if ($(step).find('.step-overlay').length === 0) {
    $(step).append('<div class="step-overlay"></div>');
  }
  $(step).find(':input:enabled:visible').attr('tabindex', '-1');
};

export default class extends Controller {
  static targets = ['companyNameInput'];

  connect() {
    const currentStepIndex = Number(this.element.dataset.currentStepIndex);

    this.updateCompanyNameNextButtonState();
    setTimeout(() => this.goToStepIndex(currentStepIndex), SCROLL_TO_STEP_DELAY_MS);
  }

  updateCompanyNameNextButtonState() {
    const companyNameSet = $(this.companyNameInputTarget).val().length > 0;

    $(this.companyNameInputTarget).closest('form')
      .find('.next-button').prop('disabled', !companyNameSet);
  }

  prevStep(event) {
    const prevStepIndex = Number(event.target.dataset.prevStepIndex);
    this.goToStepIndex(prevStepIndex);
  }

  goToStepIndex(targetIndex) {
    const allSteps = this.targets.findAll('step');
    const targetStep = allSteps[targetIndex];

    showStep(targetStep);
    scrollToStep(targetStep);

    allSteps.forEach((step, index) => {
      if (index !== targetIndex) {
        hideStep(step);
      }
    });
  }
}
