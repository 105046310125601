import Rails from '@rails/ujs';
import ContentRenderer from './content_renderer';

export default class HttpRequest {
  constructor(url, method, data, options) {
    this.url = url;
    this.method = method || 'get';
    this.data = data || '';
    this.options = options || {};
  }

  execute(callback) {
    const app = this;

    Rails.ajax({
      url: this.url,
      type: this.method,
      data: this.data,
      dataType: 'script',
      success: (data, status, xhr) => {
        new ContentRenderer(
          xhr,
          callback,
          app.options.contentTarget,
          app.options.outer
        ).render();
      },
      error: (data, status, xhr) => {
        new ContentRenderer(
          xhr,
          callback,
          app.options.contentTarget
        ).renderError();
      },
    });
  }
}
