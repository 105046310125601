import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['lineItemTotal', 'subTotal', 'taxAmount', 'total'];

  connect() {
    this.lineItemTotalTargets.forEach((e) =>
      e.addEventListener('change', this.formUpdated.bind(this))
    );
  }

  formUpdated() {
    // recalc sub total
    const subTotal = this.lineItemTotalTargets.reduce(
      (total, e) => total + Number.parseFloat(e.value),
      0
    );
    this.subTotalTarget.mask.typedValue = subTotal;

    // update taxes
    let totalTaxes = 0;
    this.taxAmountTargets.forEach((e) => {
      const taxRate = Number.parseFloat(e.dataset.taxRate);
      const taxAmount = subTotal * taxRate;
      totalTaxes += taxAmount;
      e.mask.typedValue = taxAmount;
    });

    // update total
    this.totalTarget.mask.typedValue = subTotal + totalTaxes;
  }
}
