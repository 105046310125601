import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = [
    'addCategoryButton',
    'addCategoryModal',
    'stockCategorySelect',
    'importStockModal',
    'matchStockModal',
    'stockCategory',
  ];

  connect() {
    this.allCategories = JSON.parse(this.element.dataset.allCategories);

    this.updateStockCategorySelectOptions();
  }

  get selectedCategoriesIds() {
    return this.stockCategoryTargets.map((category) =>
      Number(category.dataset.id)
    );
  }

  updateStockCategorySelectOptions() {
    const availableCategories = this.allCategories
      .filter((cat) => !this.selectedCategoriesIds.includes(cat.id))
      .sort((cat1, cat2) => cat1.name.localeCompare(cat2.name));

    $(this.stockCategorySelectTarget).empty();

    if (availableCategories.length === 0) {
      this.addCategoryButtonTarget.classList.add('d-none');
    }

    availableCategories.forEach((category) => {
      $(this.stockCategorySelectTarget).append(
        new Option(category.name, category.id)
      );
    });
  }

  sortCategories() {
    const categories = this.stockCategoryTargets;
    categories.sort((cat1, cat2) =>
      cat1.dataset.name.localeCompare(cat2.dataset.name)
    );

    $('#stock-categories').append(categories);
  }

  handleCategoryAdded() {
    this.sortCategories();
    this.updateStockCategorySelectOptions();
    $(this.addCategoryModalTarget).modal('hide');
  }

  handleStockImported() {
    this.updateStockCategorySelectOptions();
    $(this.importStockModalTarget).modal('hide');
  }

  handleStockMatched() {
    $(this.matchStockModalTarget).modal('hide');
  }
}
