import { Controller } from 'stimulus';
import jspreadsheet from 'jspreadsheet-ce';
import $ from 'jquery';
import Rails from '@rails/ujs';
import TomSelect from 'tom-select';
import toastr from 'toastr';
// FIXME: bundling css from BS4 in other places and BS5 here creates some UI glitches due to
// different CSS being applied.
import 'tom-select/dist/css/tom-select.bootstrap4.css';
// FIXME: the css for toastr is not present in the volt theme as it is for the agent themes,
//  and it seems that requiring toastr in the cat.js file + stylesheet_pack_tag are not enough
// to pull out toastr's CSS - this seems to do the job in development, at least.
import 'toastr/build/toastr.css';

const NEW_COMPONENT_ID = '-1';
const ID_COL_IDX = 0;
const COMP_NAME_COL_IDX = 1;

const minDimensions = (sectionalStock) => (sectionalStock ? [5, 1] : [3, 1]);

const removeEmptyRowsOnPaste = (originalData) =>
  originalData
    .split('\n')
    .filter((row) => row.split('\t').some((value) => !!value))
    .join('\n');

const noEmptyRowsOnSubmit = (row) => Object.values(row).some((cell) => !!cell);

const sanitizeItemForInsertion = (originalItem, catSessionId, packageId) => {
  const result = {
    ...originalItem,
    cat_session_id: catSessionId,
    package_id: packageId,
  };

  if (!originalItem.component_id) {
    result.new_component_name = originalItem.component_name;
  }

  delete result.component_name;

  return result;
};

export default class extends Controller {
  static targets = ['sheet'];

  connect() {
    const {
      stockComponentsPath,
      sectionalStock,
      editable,
      components,
      catSessionId,
      packageId,
    } = this.element.dataset;

    this.stockComponentsPath = stockComponentsPath;
    this.sectionalStock = sectionalStock === 'true';
    this.editable = editable === 'true';
    this.components = JSON.parse(components);
    this.catSessionId = catSessionId;
    this.packageId = packageId;

    this.catSheet = jspreadsheet(this.sheetTarget, {
      url: this.stockComponentsPath,
      allowComments: false,
      allowDeleteColumn: false,
      allowInsertColumn: false,
      allowRenameColumn: false,
      columnDrag: false,
      columnSorting: false,
      editable: this.editable,
      minSpareRows: 1,
      minDimensions: minDimensions(this.sectionalStock),
      tableWidth: '100%', // FIXME: this is currently broken
      columns: this.spreadsheetColumns(),

      updateTable: (instance, cell, col, row, value) => {
        if (col === COMP_NAME_COL_IDX) {
          const componentId = instance.jspreadsheet.getValue(
            instance.jspreadsheet.getCell([ID_COL_IDX, row])
          );
          // Color in red the component name cell for newly added components, i.e.,
          // the ones that have a component name value but no id.
          if (!!value && !componentId) {
            cell.style.backgroundColor = '#ffcccc';
          } else {
            cell.style.backgroundColor = '#ffffff';
          }
        }
      },

      onbeforepaste: (instance, data) => {
        this.pastingContent = true;

        // Disable the readonly property on the id column before the paste event.
        instance
          .querySelectorAll('tbody td.readonly[data-x="0"]')
          .forEach((cell) => cell.classList.remove('readonly'));

        // Exclude any totally empty rows from being pasted.
        return removeEmptyRowsOnPaste(data);
      },

      onpaste: (instance) => {
        this.pastingContent = false;

        // Re-enable the readonly property on the id column after the paste event.
        instance
          .querySelectorAll('tbody td[data-x="0"]')
          .forEach((cell) => cell.classList.add('readonly'));
      },

      oninsertrow: (instance) => {
        if (this.pastingContent) {
          // When pasting content and new rows need to be inserted, also disable the readonly
          // property on them temporarily (it will then be re-enabled after paste).
          instance
            .querySelectorAll('tbody td.readonly[data-x="0"]')
            .forEach((cell) => cell.classList.remove('readonly'));
        }
      },

      ondeleterow: this.showUnsavedChangesAlert,
      onchange: this.showUnsavedChangesAlert,
    });
  }

  spreadsheetColumns() {
    const columns = [
      {
        title: 'Id',
        name: 'component_id',
        type: 'numeric',
        mask: '0',
        width: 60,
        readOnly: true,
      },
      {
        title: 'Select a component',
        name: 'component_name',
        editor: this.searchComponentsEditor(),
        align: 'left',
        width: 450,
      },
    ];

    // Sectional stock has 3 quantity columns for each bay type.
    if (this.sectionalStock) {
      return columns.concat([
        {
          title: 'Starter bay qty',
          name: 'marquee_starter_bay_quantity',
          type: 'numeric',
          mask: '0',
          width: 150,
        },
        {
          title: 'Additional bay qty',
          name: 'marquee_additional_bay_quantity',
          type: 'numeric',
          mask: '0',
          width: 150,
        },
        {
          title: 'End bay qty',
          name: 'marquee_end_bay_quantity',
          type: 'numeric',
          mask: '0',
          width: 150,
        },
      ]);
    }

    // Regular stock has a single quantity col.
    return columns.concat([
      {
        title: 'Qty',
        name: 'quantity',
        type: 'numeric',
        mask: '0',
        width: 150,
      },
    ]);
  }

  searchComponentsEditor = () => ({
    updateCell: (cell, value) => {
      if (value) {
        cell.innerHTML = value;
      }

      return value;
    },

    openEditor: (cell, instance) => {
      cell.setAttribute('data-previous-component-name', cell.innerHTML);

      cell.classList.add('editor');
      cell.innerHTML = '';
      const selectElement = document.createElement('select');
      cell.appendChild(selectElement);

      this.searchComponentsSelect = new TomSelect(selectElement, {
        maxItems: 1,
        create: (name) => ({ id: -1, name }),
        valueField: 'id',
        searchField: 'name',
        labelField: 'name',
        openOnFocus: true,
        options: this.components,
        closeAfterSelect: true,
        onItemAdd: (value, $item) => {
          cell.setAttribute('data-component-id', value);
          cell.setAttribute('data-component-name', $item.textContent);
        },
        onDropdownClose: () => {
          // Unmount search component
          if (this.searchComponentsSelect) {
            this.searchComponentsSelect.clearCache();
            this.searchComponentsSelect.destroy();
            this.searchComponentsSelect = null;
          }

          instance.jspreadsheet.closeEditor(cell, true);
        },
      });

      // Trigger the dropdown immedtiately after mounted
      this.searchComponentsSelect.focus();
    },

    closeEditor: (cell) => {
      const { y, componentId, componentName } = cell.dataset;

      // FIXME: right now clicking with the mouse outside on first open and ESC on the
      // second crashes the select (clicking outside twice in a row, too).

      if (!componentId || !componentName) {
        const prevContent = cell.dataset.previousComponentName;
        cell.removeAttribute('data-previous-component-name');

        return prevContent;
      }

      const idCellName = jspreadsheet.getColumnNameFromId([ID_COL_IDX, y]);

      // We need to force set the value since the id column is set as read only.
      this.catSheet.setValue(
        idCellName,
        componentId === NEW_COMPONENT_ID ? '' : componentId,
        true
      );

      cell.innerHTML = componentName;

      cell.removeAttribute('data-component-id');
      cell.removeAttribute('data-component-name');

      return cell.innerHTML;
    },
  });

  addEmptyRow(event) {
    event.preventDefault();

    this.catSheet.insertRow();
  }

  showUnsavedChangesAlert = () => {
    const errorAlert = this.element.querySelector('.unsaved-changes-alert');
    if (errorAlert.classList.contains('show')) {
      return;
    }

    // Make the alert inline block so it doesn't take 100% of the card width
    errorAlert.classList.add('d-inline-block');
    $(errorAlert).collapse('show');
  };

  async save(event) {
    const submitButton = event.currentTarget;

    // Manually disable the button to save changes using rails-ujs built-in disable method.
    Rails.disableElement(submitButton);

    const components = this.sheetTarget.jspreadsheet
      .getJson()
      .filter(noEmptyRowsOnSubmit)
      .map((item) =>
        sanitizeItemForInsertion(item, this.catSessionId, this.packageId)
      );

    const token = document.querySelector('meta[name="csrf-token"]').content;
    const response = await fetch(this.element.dataset.saveDataUrl, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ package_id: this.packageId, components }),
    });

    const { status } = response;

    if (status >= 200 && status < 300) {
      window.location.hash = `#stock-package-${this.packageId}-spreadsheet`;
      window.location.reload(true);
    } else {
      toastr.error('Failed to save your changes. Please try again');
      // Re-enable the submit button so the user can try again
      Rails.enableElement(submitButton);
    }
  }
}
