import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

const ENTER_KEY_CODE = 13;

export default class extends Controller {
  static targets = ['marqueeGuid', 'wizardAction', 'step', 'eventInformation'];

  configureMarquee(event) {
    this.marqueeGuidTarget.value = event.currentTarget.dataset.marqueeGuid;
    this.wizardActionTarget.value = 'jump_to_step';
    this.stepTarget.value = event.currentTarget.dataset.configureStepId;
  }

  nextStep(event) {
    if (this.hasWizardActionTarget) {
      this.wizardActionTarget.value = 'next_step';
    } else {
      const { stepId } = event.currentTarget.dataset;
      this.redirectToStep(stepId);
    }
  }

  prevStep(event) {
    if (this.hasWizardActionTarget) {
      this.wizardActionTarget.value = 'prev_step';
    } else {
      const { stepId } = event.currentTarget.dataset;
      this.redirectToStep(stepId);
    }
  }

  skipToQuote(event) {
    this.wizardActionTarget.value = 'skip_to_quote';
    if (this.hasMarqueeGuidTarget) {
      Rails.fire(this.marqueeGuidTarget.form, 'submit');
      event.preventDefault();
    } else if (this.hasEventInformationTarget) {
      const form = event.target.closest('form');
      Rails.fire(form, 'submit');
      event.preventDefault();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  preventEnterSubmit(event) {
    if (event.keyCode === ENTER_KEY_CODE) {
      event.preventDefault();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  redirectToStep(stepId) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('step', stepId);
    window.location.search = urlParams;
  }
}
