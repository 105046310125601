import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['collapseButton', 'videoContent'];

  connect() {
    this.currentState = 'collapsed';

    $(this.videoContentTarget).on('hide.bs.collapse', () => {
      this.toggleState();
    });

    $(this.videoContentTarget).on('show.bs.collapse', () => {
      this.toggleState();
    });
  }

  toggleState() {
    if (this.currentState === 'collapsed') {
      this.currentState = 'expanded';
      this.collapseButtonTarget.innerText = 'Hide video';
      this.collapseButtonTarget.classList.remove('btn-info');
      this.collapseButtonTarget.classList.add('btn-danger');
    } else {
      this.currentState = 'collapsed';
      this.collapseButtonTarget.innerText = 'Show video';
      this.collapseButtonTarget.classList.remove('btn-danger');
      this.collapseButtonTarget.classList.add('btn-info');
    }
  }
}
