import { Controller } from 'stimulus';
import $ from 'jquery';

const GROUPING_COLUMN_IDX = 2;

export default class extends Controller {
  static targets = [
    'modal',
    'modalBody',
    'stockTable',
    'stockTableWrapper',
    'stockItemsContainer',
    'submitButton',
  ];

  connect() {
    const controllerThis = this;

    $(this.modalTarget)
      .on('shown.bs.modal', async () => {
        if (!this.lazyLoaded) {
          const r = await fetch(this.element.dataset.bulkAddStockDetailsUrl);
          controllerThis.modalBodyTarget.innerHTML = await r.text();
          controllerThis.lazyLoaded = true;
          controllerThis.modalInit();
        } else {
          this.modalInit();
        }
      })
      .on('hide.bs.modal', () => {
        this.modalDestroy();
      });
  }

  disconnect() {
    $(this.modalTarget).off('shown.bs.modal');
    $(this.modalTarget).off('hide.bs.modal');
  }

  modalInit() {
    this.resetSelectedStock();
    this.setupDataTable();
  }

  modalDestroy() {
    // The table should be hidden until the initComplete callback from datatables
    // comes in, to prevent a weird UI glitch when applying datatables. So we reset
    // it here to the initial (hidden) state, in case the modal is opened again.
    $(this.stockTableWrapperTarget).addClass('d-none');

    this.dataTable.destroy();
    this.dataTable = null;
    // Reset scroll position on the table
    $(this.stockTableWrapperTarget).parent().scrollTop(0);

    // Reset stock to its initial state
    this.resetSelectedStock();
  }

  setupDataTable() {
    const controllerThis = this;

    if (!this.dataTable) {
      const dataTableConfig = {
        ordering: false,
        paging: false,
        dom: `
          <'row'<'col-sm-12'f>>
          <'row overflow-auto'<'col-sm-12 mh-60vh'tr>>
        `,
        language: {
          searchPlaceholder: 'Search for products',
          search: '',
        },
        initComplete() {
          // Show the table only after datatables has finished initializing to prevent
          // a UI glitch.
          $(controllerThis.stockTableWrapperTarget).removeClass('d-none');
        },
        drawCallback() {
          // Take the grouping column (stock type) and use it for grouping stock
          // items under the same group in the table.
          const api = this.api();
          const rows = api.rows({ page: 'current' }).nodes();
          let last = null;

          api
            .column(GROUPING_COLUMN_IDX, { page: 'current' })
            .data()
            .each((group, index) => {
              if (last !== group) {
                $(rows)
                  .eq(index)
                  .before(
                    `<tr class="group">
                      <td colspan="4">
                        <p class="my-2">${group}</p>
                      </td>
                    </tr>`
                  );
                last = group;
              }
            });

          // UI fix for the search bar text size, etc.
          $(controllerThis.stockTableWrapperTarget)
            .find('.dataTables_filter input')
            .removeClass('form-control-sm');
        },
      };

      this.dataTable = $(this.stockTableTarget).DataTable(dataTableConfig);
    }
  }

  resetSelectedStock() {
    // Remove all stock items HTML from the form
    $(this.stockItemsContainerTarget).find('.stock-item-input-row').remove();

    // Clear the selected stock
    this.selectedStock = [];

    // Also uncheck any checked checkbox
    $(this.stockTableTarget)
      .find('input[type=checkbox]')
      .prop('checked', false);

    this.updateSubmitButtonState();
  }

  stockItemToggled(event) {
    event.preventDefault();

    const { stockItemId, stockItemName } = event.target.dataset;

    if (event.target.checked) {
      this.addStockItemInput(stockItemId, stockItemName);
    } else {
      this.removeStockItemInput(stockItemId);
    }

    this.updateSubmitButtonState();
  }

  removeStockItemClicked(event) {
    event.preventDefault();

    const stockItemId = $(event.target)
      .parents('.stock-item-input-row')
      .data('stockItemId');

    // Remove the stock item input from the form
    this.removeStockItemInput(stockItemId);

    // Uncheck the item form the left hand panel
    $(this.stockTableTarget)
      .find(`input[type=checkbox][data-stock-item-id=${stockItemId}]`)
      .prop('checked', false);

    this.dataTable.rows().invalidate();

    this.updateSubmitButtonState();
  }

  addStockItemInput(stockItemId, stockItemName) {
    // Append the stock item input HTML
    $(this.stockItemsContainerTarget).append(`
      <div class="stock-item-input-row d-flex align-items-center mb-2" data-stock-item-id="${stockItemId}">
        <div class="marquee-name flex-grow-1">${stockItemName}</div>
        <div class="req-amounts d-flex mr-auto ml-2">
          <div class="input-group ml-2" style="width: 120px">
            <span class="input-group-prepend">
              <span class="input-group-text">Qty</span>
            </span>
            <input
              type="hidden"
              name="stock_requirements[${stockItemId}][stock_id]"
              value="${stockItemId}"
            />
            <input
              required="required"
              min="0"
              autocomplete="off"
              class="form-control text-right"
              type="number"
              value="1"
              name="stock_requirements[${stockItemId}][quantity]"
              id="stock_item_${stockItemId}_quantity"
            />
          </div>
        </div>
        <a href="#" class="mx-2" data-action="event-quotes--bulk-add-stock-items#removeStockItemClicked">
          <i class="fa fa-trash text-danger"></i>
        </a>
      </div>
    `);

    // Add the item to the selected stock array
    this.selectedStock.push(parseInt(stockItemId, 10));

    // Scroll to the bottom of the div when a new item is added at the end
    this.stockItemsContainerTarget.scroll({
      top: this.stockItemsContainerTarget.scrollHeight,
    });
  }

  removeStockItemInput(stockItemId) {
    // Remove the item's HTML from the form
    $(this.stockItemsContainerTarget)
      .find(`.stock-item-input-row[data-stock-item-id=${stockItemId}]`)
      .remove();

    // Remove the item from the selected stock array
    this.selectedStock = this.selectedStock.filter(
      (id) => id !== parseInt(stockItemId, 10)
    );
  }

  updateSubmitButtonState() {
    if (this.selectedStock.length > 0) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }
}
