import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['editorContainer', 'hiddenField'];

  connect() {
    this.fileType = this.element.dataset.fileType;
    this.editor = monaco.editor.create(this.editorContainerTarget, {
      value: this.hiddenFieldTarget.value,
      language: this.fileType,
      theme: 'vs-dark',
    });
    this.hiddenFieldTarget.form.addEventListener(
      'submit',
      this.formSubmitted.bind(this)
    );
  }

  formSubmitted() {
    this.hiddenFieldTarget.value = this.editor.getValue();
  }
}
