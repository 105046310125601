import $ from 'jquery';
import moment from 'moment';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

import BulkActionsController from '../shared/bulk_actions_controller';

export default class extends BulkActionsController {
  static targets = ['reportDateRange', 'bookingsTable'];

  connect() {
    const initialDates = [
      moment(this.element.dataset.startDate).format(),
      moment(this.element.dataset.endDate).format(),
    ];
    this.dateRange = flatpickr(this.reportDateRangeTarget, {
      defaultDate: initialDates,
      allowInput: true,
      mode: 'range',
      placeholder: 'Please select a date range',
      onChange: () => this.updatedDateRange(),
    });

    $(this.bookingsTableTarget).DataTable({
      searching: false,
      conditionalPaging: true,
      conditionalPageLength: true,
      dom:
        "<'row'<'col-12 table-responsive text-nowrap'tr>>" +
        "<'d-flex justify-content-end'<'pt-3'p><'pt-3'l>>",
      language: {
        lengthMenu: `
        <div class="form-inline">
          <div class="input-group ml-2">
            <div class="input-group-prepend">
              <div class="input-group-text" for="page-size-select">
                Items per page
              </div>
            </div>
            <select name="page_size" id="page-size-select" class="custom-select">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        `,
      },
    });
  }

  updatedDateRange() {
    const {
      selectedDates: [startDate, endDate],
    } = this.dateRange;
    if (!startDate || !endDate) {
      return;
    }

    const urlParams = this.currentUrlSearchParams();
    urlParams.set('start_date', moment(startDate).format());
    urlParams.set('end_date', moment(endDate).format());
    // Delete the currently set date range with the select picker
    urlParams.delete('date_range');

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  predefinedDateRangeSelected(event) {
    const dateRange = event.currentTarget.value;

    const urlParams = this.currentUrlSearchParams();
    // Delete the currently set dates with the calendar picker
    urlParams.delete('start_date');
    urlParams.delete('end_date');

    // Display the results starting from the first page.
    urlParams.delete('page');

    if (dateRange) {
      urlParams.set('date_range', dateRange);
    }

    this.applyURLParams(urlParams);
  }

  updatedDateField(event) {
    const dateField = event.currentTarget.value;

    const urlParams = this.currentUrlSearchParams();

    // Display the results starting from the first page.
    urlParams.delete('page');

    if (dateField) {
      urlParams.set('date_field', dateField);
    }

    this.applyURLParams(urlParams);
  }

  hideQuotesToggled(event) {
    const { checked } = event.target;
    const urlParams = this.currentUrlSearchParams();
    urlParams.set('hide_quotes', checked);

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  currentUrlSearchParams = () => new URLSearchParams(window.location.search);

  applyURLParams = (urlParams) => {
    window.location.search = urlParams;
  };

  selectedBookingsParams = () => {
    const checkboxes = document.getElementsByName('select-row');
    const params = new URLSearchParams();

    checkboxes.forEach((checkbox, index) => {
      if (checkbox.checked) {
        params.append(
          `event_quotes[${index}][guid]`,
          checkbox.dataset.eventQuoteGuid
        );
      }
    });

    return params;
  };

  bulkPrintLoadListRequest = (event) => {
    event.preventDefault();
    const data = this.selectedBookingsParams();
    const serializedData = data.toString();
    const url =
      event.target.dataset.bulkPrintLoadListPath + '?' + serializedData;
    const newWindow = window.open(url, '_blank');
    newWindow.focus();
  };

  bulkPrintDeliveryNoteRequest = (event) => {
    event.preventDefault();
    const data = this.selectedBookingsParams();
    const serializedData = data.toString();
    const url =
      event.target.dataset.bulkPrintDeliveryNotePath + '?' + serializedData;
    const newWindow = window.open(url, '_blank');
    newWindow.focus();
  };
}
