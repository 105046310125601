import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['blockDataTypeSelect'];

  blockDataTypeChanged(event) {
    const {
      replaceBlockDataTypeUrl,
      htmlTargetId,
      fieldPrefix,
    } = this.blockDataTypeSelectTarget.dataset;

    Rails.ajax({
      type: 'get',
      url: replaceBlockDataTypeUrl,
      dataType: 'script',
      data: new URLSearchParams({
        html_target_id: htmlTargetId,
        parent_field: fieldPrefix,
        block_data_type: event.target.value,
      }).toString(),
    });
  }
}
