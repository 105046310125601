import { Controller } from 'stimulus';
import 'flatpickr/dist/flatpickr.min.css';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'depositAmount',
    'depositAmountHidden',
    'depositPercentage',
    'depositPercentageHidden',
    'finalAmount',
    'unlockDepositAmountButton',
  ];

  connect() {
    if (this.hasDepositPercentageTarget) {
      this.depositPercentageTarget.addEventListener(
        'change',
        this.depositPercentageUpdated.bind(this)
      );
      this.depositPercentageTarget.addEventListener(
        'keydown',
        this.updateAndSubmit.bind(this)
      );
    }
    if (this.hasDepositAmountTarget) {
      this.depositAmountTarget.addEventListener(
        'change',
        this.depositAmountUpdated.bind(this)
      );
      this.depositAmountTarget.addEventListener(
        'keydown',
        this.updateAndSubmit.bind(this)
      );
    }
    if (this.hasUnlockDepositAmountButtonTarget) {
      this.unlockDepositAmountButtonTarget.addEventListener(
        'click',
        this.unlockDepositAmount.bind(this)
      );
    }
  }

  depositPercentageUpdated() {
    const depositAmount = (
      (this.depositPercentageTarget.value / 100.0) *
      this.eventQuoteTotal()
    ).toFixed(2);
    this.depositAmountTarget.value = depositAmount;
    // this.depositAmountHiddenTarget.value = depositAmount;
    this.finalAmountTarget.value = (
      this.eventQuoteTotal() - this.depositAmountTarget.mask.typedValue
    ).toFixed(2);
  }

  depositAmountUpdated() {
    const depositPercentage = (
      (this.depositAmountTarget.mask.typedValue / this.eventQuoteTotal()) *
      100.0
    ).toFixed(2);
    this.depositPercentageTarget.value = depositPercentage;
    this.depositPercentageHiddenTarget.value = depositPercentage;
    this.finalAmountTarget.value = (
      this.eventQuoteTotal() - this.depositAmountTarget.mask.typedValue
    ).toFixed(2);
  }

  eventQuoteTotal() {
    return this.data.get('quote-total');
  }

  unlockDepositAmount(e) {
    e.preventDefault();
    this.depositAmountTarget.disabled = false;
    this.depositPercentageTarget.disabled = true;
  }

  updateAndSubmit(event) {
    if (event.keyCode === 13) {
      if (event.currentTarget === this.depositAmountTarget) {
        this.depositAmountUpdated();
      } else {
        Rails.fire(this.depositAmountTarget.form, 'submit');
      }
    }
  }
}
