import BaseSpreadsheetController from './base_spreadsheet_controller';

export default class extends BaseSpreadsheetController {
  editableColumns = () => [
    {
      title: 'Category <span class="text-danger">*</span>',
      name: 'category',
      width: 175,
      wordWrap: true,
    },
    {
      title: 'Product name <span class="text-danger">*</span>',
      name: 'name',
      width: 650,
      wordWrap: true,
    },
    {
      title: 'QTY in stock <span class="text-danger">*</span>',
      name: 'quantity',
      width: 75,
      type: 'numeric',
      align: 'right',
      mask: '0',
    },
    {
      title: 'Hire Price <span class="text-danger">*</span>',
      name: 'rate',
      width: 75,
      type: 'numeric',
      align: 'right',
      mask: '0.00',
    },
    {
      title: 'Sub Hired?',
      name: 'cross_hire',
      width: 75,
      type: 'checkbox',
      align: 'center',
    },
    {
      title: 'Cost to sub hire',
      name: 'cost_type_flat_fee',
      width: 75,
      type: 'numeric',
      align: 'right',
      mask: '0.00',
    },
  ];

  prepareDataForSubmission = () => ({
    stock: this.sheetTarget.jspreadsheet
      .getJson()
      .map(
        ({
          id,
          category,
          name,
          quantity,
          rate,
          cross_hire,
          cost_type_flat_fee,
        }) => ({
          id,
          category,
          name,
          quantity,
          rate,
          cross_hire,
          cost_type_attributes: {
            stock_id: id,
            flat_fee: cost_type_flat_fee,
          },
        })
      ),
  });
}
