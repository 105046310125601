import BaseSpreadsheetController from '../initial_account_setup/base_spreadsheet_controller';

export default class extends BaseSpreadsheetController {
  connect() {
    this.products = JSON.parse(this.element.dataset.products);
    // this.stockTypeName = this.element.dataset.stockTypeName;
    super.connect();
  }

  editableColumns = () => [
    {
      title: 'Component name <span class="text-danger">*</span>',
      name: 'component_name',
      width: 350,
      wordWrap: true,
    },
    {
      title: 'Products <span class="text-danger">*</span>',
      name: 'package_ids',
      width: 450,
      type: 'dropdown',
      source: this.products,
      multiple: true,
      autocomplete: true,
      wordWrap: true,
    },
  ];

  prepareDataForSubmission = () => {
    const sheetData = this.sheetTarget.jspreadsheet.getJson();
    const filteredData = sheetData
      .filter((sd) => sd.component_name.length > 0)
      .map((sd) => ({
        component_id: sd.id,
        component_name: sd.component_name,
        package_ids: Array.isArray(sd.package_ids)
          ? sd.package_ids
          : sd.package_ids.split(';'),
      }));
    return { components: filteredData };
  };
}
