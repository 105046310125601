import Chart from 'chart.js';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const { dataset: { chartData } } = this.element;
    this.parsedChartData = JSON.parse(chartData);
    const colours = [
      'rgba(255, 99, 132, 0.5)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(255, 99, 132, 0.5)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(255, 99, 132, 0.5)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(255, 99, 132, 0.5)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(255, 99, 132, 0.5)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
    ];
    const labels = Object.keys(this.parsedChartData);
    const datasets = labels.map((label) => {
      const index = labels.indexOf(label);
      return {
        label,
        data: [this.totalFor(label)],
        backgroundColor: colours[index],
      };
    });
    const data = {
      type: 'bar',
      data: {
        datasets,
      },
      options: {
        title: {
          display: false,
        },
      },
    };
    this.chart = new Chart(this.element, data);
  }

  totalFor(key) {
    if (!Object.prototype.hasOwnProperty.call(this.parsedChartData, key)) return 0;
    return this.parsedChartData[key]
      .map((c) => c.total)
      .reduce((a, b) => Number(a) + Number(b), 0);
  }
}
