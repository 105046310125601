import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['quantity', 'rate', 'template', 'total', 'quoteTotal'];

  connect() {
    this.totalTargets.forEach((el) =>
      el.addEventListener('change', this.updateTotals.bind(this))
    );
  }

  addQuoteItem(e) {
    const addItemContainer = e.currentTarget.closest('tr');
    addItemContainer.before(this.templateTarget.content.cloneNode(true));
    e.preventDefault();
  }

  deleteQuoteItem(e) {
    e.currentTarget.closest('tr').remove();
    this.updateTotals();
    e.preventDefault();
  }

  updateTotals() {
    this.quoteTotalTarget.value = this.totalTargets.reduce(
      (sum, t) => sum + Number(t.value),
      0
    );
  }
}
