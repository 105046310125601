import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['modelSelect', 'fieldSelect', 'destroyInput', 'editorContainer'];

  handleModelChange(event) {
    $(this.fieldSelectTarget).empty();

    const selectedModelPath = event.target.value;
    const fieldsMap = JSON.parse(this.fieldSelectTarget.dataset.fieldsMap);
    const { fields } = fieldsMap.find((it) => it.path === selectedModelPath);

    fields.forEach((field) => {
      const option = $('<option></option>').attr('value', field.name).text(field.label);
      $(this.fieldSelectTarget).append(option);
    });
  }

  removeContentField() {
    this.editorContainerTarget.remove();
  }
}
