import $ from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.css';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['stockSelect']

  connect() {
    const stockSearchPath = this.data.get('stock-search-path');
    const prompt = this.data.has('prompt') ? this.data.get('prompt') : 'Find stock...';
    $(this.stockSelectTarget).select2({
      placeholder: prompt,
      allowClear: true,
      minimumInputLength: 3,
      width: '100%',
      ajax: {
        url: stockSearchPath,
        dataType: 'json',
      },
    });
  }
}
