export default class Value {

  static isPresent(v) {
    return v != null && v != '';
  }

  static isBlank(v) {
    return v == null || v == '';
  }

  static isObject(v) {
    return v && typeof v === 'object' && v.constructor === Object;
  }
  static isString(v) {
    return v && typeof v === 'string' && v.constructor === String;
  }

  static toString(object) {
    if (Value.isString(object)) {
      return object;
    }

    var str = '';
    for (var k in object) {
      if (object.hasOwnProperty(k)) {
        str += k + '=' + object[k] + ', ';
      }
    }

    return str;
  }
}
