import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['value', 'labelName'];

  updateLabel(event) {
    const fileName = event.target.value.split('\\').pop();
    this.labelNameTarget.innerHTML = `${fileName} ready, click me to save logo`;
    this.labelNameTarget.classList.add('d-block');
  }
}
