import BaseSpreadsheetController from './base_spreadsheet_controller';

export default class extends BaseSpreadsheetController {
  editableColumns = () => [
    {
      title: 'Category <span class="text-danger">*</span>',
      name: 'category',
      width: 175,
      wordWrap: true,
    },
    {
      title: 'Component name <span class="text-danger">*</span>',
      name: 'name',
      width: 350,
      wordWrap: true,
    },
    {
      title: 'QTY in stock <span class="text-danger">*</span>',
      name: 'quantity',
      width: 75,
      type: 'numeric',
      align: 'right',
      mask: '0',
    },
  ];

  prepareDataForSubmission = () => {
    return {
      stock: this.sheetTarget.jspreadsheet.getJson(),
    };
  };
}
