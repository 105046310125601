import BaseSpreadsheetController from './base_spreadsheet_controller';

export default class extends BaseSpreadsheetController {
  connect() {
    this.vendors = JSON.parse(this.element.dataset.vendors);
    super.connect();
  }

  // the vendors column is disabled if there are no vendors to select from,
  // because the dropdown ui has a glitch when no options are provided.
  editableColumns = () => [
    {
      title: 'Cost group <span class="text-danger">*</span>',
      name: 'cost_group',
      width: 250,
      wordWrap: true,
    },
    {
      title: 'Item name <span class="text-danger">*</span>',
      name: 'name',
      width: 375,
      wordWrap: true,
    },
    {
      title: 'Cost <span class="text-danger">*</span>',
      name: 'flat_fee',
      type: 'numeric',
      align: 'right',
      mask: '0.00',
      width: 75,
    },
    {
      title: 'Vendor',
      name: 'vendor_id',
      width: 150,
      type: 'dropdown',
      source: this.vendors,
      wordWrap: true,
      readOnly: this.vendors.length === 0,
    },
  ];

  prepareDataForSubmission = () => ({
    cost_types: this.sheetTarget.jspreadsheet.getJson(),
  });
}
