import { Controller } from 'stimulus';
import ButtonClickRequest from '../../helpers/button_click_request';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

export default class extends Controller {
  connect() {
    this.lastValue = null;
    const app = this;
    const args = {
                    wrap: this.element.querySelector('[data-input]') ? true : false,
                    allowInput: true,
                    altInput: true,
                    altFormat: 'F j, Y',
                    dateFormat: 'Y-m-d',
                    onChange: (selectedDates, dateStr, instance) => {
                                app.onChange(selectedDates, dateStr, instance);
                              }
                  };

    flatpickr(this.element, args);
  }

  onChange(selectedDates, dateStr, instance) {
    if (this.element.dataset.sendHttpUpdate != 'true' || this.lastValue == dateStr) {
      return;
    }
    this.lastValue = dateStr;
    new ButtonClickRequest(this.element).execute();
  }
}
