import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['discountButtonContainer', 'discountInputContainer'];

  addDiscount(event) {
    event.preventDefault();
    this.discountInputContainerTarget.classList.remove('d-none');
    this.discountButtonContainerTarget.classList.add('d-none');

    $(this.discountInputContainerTarget).find('input').trigger('focus');
  }
}
