import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dateField", "formContainer"]

  reloadForm() {
    const date = this.dateFieldTarget.value
    fetch(`/timesheets/bulk_edit?date=${date}`, {
      headers: { "Accept": "text/plain" }
    })
    .then(response => response.text())
    .then(html => this.formContainerTarget.innerHTML = html)
  }
}