import ClipboardJS from 'clipboard';
import toastr from 'toastr';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.clipboard = new ClipboardJS(this.element);
    this.clipboard.on('success', (e) => {
      toastr.success('Link copied to clipboard');
      e.clearSelection();
    });
  }

  disconnect() {
    if (this.clipboard) {
      this.clipboard.off('success');
    }
  }
}
