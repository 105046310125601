import BaseSpreadsheetController from './base_spreadsheet_controller';

export default class extends BaseSpreadsheetController {
  connect() {
    this.marquees = JSON.parse(this.element.dataset.marquees);
    super.connect();
  }

  editableColumns = () => [
    {
      title: 'Select the tent to add the custom bay to<span class="text-danger">*</span>',
      name: 'marquee_id',
      width: 175,
      type: 'dropdown',
      source: this.marquees,
    },
    {
      title: 'Custom bay name<span class="text-danger">*</span>',
      name: 'name',
      width: 425,
      wordWrap: true,
    },
    {
      title: 'Length (m) <span class="text-danger">*</span>',
      name: 'length',
      width: 85,
      type: 'numeric',
      align: 'right',
      mask: '0.00',
    },
    {
      title: 'Price <span class="text-danger">*</span>',
      name: 'rate',
      width: 85,
      type: 'numeric',
      align: 'right',
      mask: '0.00',
    },
  ];

  prepareDataForSubmission = () => ({
    stock: this.sheetTarget.jspreadsheet
      .getJson()
      .map(
        ({
          id,
          marquee_id,
          name,
          rate,
          length,
        }) => ({
          id,
          marquee_id,
          name,
          rate,
          details: {
            length,
          },
        })
      ),
  });
}
