import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['customTerms', 'standardTermsCheckbox'];

  editCustomTerms() {
    if (this.standardTermsCheckboxTarget.checked) {
      this.standardTermsCheckboxTarget.checked = false;
      this.customTermsTarget.value = '';
      this.customTermsTarget.disabled = false;
    }
  }

  toggleCustomTerms() {
    if (this.standardTermsCheckboxTarget.checked) {
      this.customTermsTarget.value = '';
      this.customTermsTarget.disabled = true;
    } else {
      this.customTermsTarget.value = '';
      this.customTermsTarget.disabled = false;
    }
  }
}
