import { Controller } from 'stimulus';
import HttpRequest from '../../helpers/http_request';
import Element from '../../helpers/element';

export default class extends Controller {
  click(e) {
    e.preventDefault();

    const target = Element.closestAnchorLinkOrButton(e.target);

    this.showOverlay(target);

    this.loadContent(target);

    this.bindCloseEvents(target);
  }

  showOverlay(target) {
    let overlayEl = document.createElement('div');
    overlayEl.classList.add('bs-canvas-overlay', 'bg-dark', 'position-fixed', 'w-100', 'h-100');
    document.querySelector('body').prepend(overlayEl);

    let canvasSelector = '.bs-canvas-right';
    if (target.dataset.canvasId) {
      canvasSelector = `#${target.dataset.canvasId}.bs-canvas-right`;
    }
    document.querySelector(canvasSelector).classList.add('mr-0');
  }

  loadContent(target) {
    const contentTarget = `#${target.dataset.canvasId}-bs-canvas-content`;
    const httpRequest = new HttpRequest(target.dataset.url, 'get', '', { contentTarget: contentTarget});
    httpRequest.execute();
  }

  bindCloseEvents(target) {
    for (let el of document.querySelectorAll('.bs-canvas-close, .bs-canvas-overlay')) {
      el.addEventListener('click', () => {
        let elm = null;
        if (el.classList.contains('bs-canvas-close')) {
          elm = el.closest('.bs-canvas');
        } else {
          elm = document.querySelector('.bs-canvas');
        }

        if (elm) {
          elm.classList.remove('mr-0', 'ml-0');
        }

        const overlay = document.querySelector('.bs-canvas-overlay');
        if (overlay) {
          overlay.remove();
        }

        //el.removeEventListener('click');

        if (target.dataset.onCloseReloadUrl) {
          fetch(target.dataset.onCloseReloadUrl, {
            headers: { Accept: "text/vnd.turbo-stream.html" },
            method: this.element.dataset.method || 'GET',
          }).then(r => r.text())
            .then(html => Turbo.renderStreamMessage(html));

        }
      });
    }
  }
}
