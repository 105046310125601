import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'destroyInput',
    'stockItemContainer',
  ];

  removeStockItem() {
    this.destroyInputTarget.value = true;
    this.stockItemContainerTarget.classList.remove('d-flex');
    this.stockItemContainerTarget.classList.add('d-none');
  }
}
