import axios from 'axios';
import $ from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.css';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'stockSelect',
    'packageSelect',
    'basedOnStockContainer',
    'basedOnStockSelect',
    'basedOnPackageSelect',
  ];

  connect() {
    const options = JSON.parse(this.element.dataset.options);
    this.constructor.setupPackageDropdown({
      packageSelectElement: this.packageSelectTarget,
      componentSelectElement: this.stockSelectTarget,
      options: options.filter((o) => o.package),
    });
    this.constructor.setupPackageDropdown({
      packageSelectElement: this.basedOnPackageSelectTarget,
      componentSelectElement: this.basedOnStockSelectTarget,
      options,
      containerElement: this.basedOnStockContainerTarget,
    });
  }

  static setupPackageDropdown({
    packageSelectElement, componentSelectElement, options, containerElement = null,
  }) {
    $(packageSelectElement).select2({
      placeholder: 'Select package/stock to base quantity on',
      allowClear: true,
      width: '100%',
      data: options,
    });
    $(componentSelectElement).select2({
      placeholder: 'Select stock to base quantity on',
      allowClear: true,
      width: '100%',
      data: [],
    });
    $(packageSelectElement).on('select2:select', async ({ params: { data } }) => {
      if (data.package) {
        const r = await axios.get(`/api/stock?components_for_package=${data.id}`);
        if (r.status === 200) {
          $(componentSelectElement).empty();
          $(componentSelectElement).select2({
            placeholder: 'Select stock to base quantity on',
            allowClear: true,
            width: '100%',
            data: r.data.results[0].children,
          });
          $(componentSelectElement).prop('disabled', false);
          if (containerElement) {
            containerElement.classList.remove('d-none');
          }
        }
      } else {
        $(componentSelectElement).prop('disabled', true);
        $(componentSelectElement).val(data.id);
        if (containerElement) {
          containerElement.classList.add('d-none');
        }
      }
    });
    $(packageSelectElement).on('select2:clear', () => {
      $(componentSelectElement).prop('disabled', true);
      $(componentSelectElement).val(null);
      if (containerElement) {
        containerElement.classList.add('d-none');
      }
    });
  }
}
