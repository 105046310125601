import toastr from 'toastr';
import TomSelect from 'tom-select';
import 'tom-select/dist/css/tom-select.bootstrap4.css';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'country',
    'emailHidden',
    'emailInput',
    'loginLink',
    'loginModal',
    'regionPicker',
  ];

  connect() {
    this.regions = JSON.parse(this.element.dataset.regions);
    this.emailLookupPath = this.element.dataset.emailLookupPath;
    this.ts = new TomSelect(this.regionPickerTarget, {});
    this.countryTarget.addEventListener(
      'change',
      this.updateRegionSelection.bind(this)
    );
    this.emailInputTarget.addEventListener(
      'change',
      this.emailUpdated.bind(this)
    );
    this.loginLinkTarget.addEventListener(
      'click',
      this.showLoginModal.bind(this)
    );
    $(this.loginModalTarget).on(
      'hidden.bs.modal',
      this.loginModalHidden.bind(this)
    );

    this.updateRegionSelection();
  }

  async emailUpdated() {
    const email = this.emailInputTarget.value;
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return;
    }

    // call lookup email service
    const r = await fetch(`${this.emailLookupPath}?email=${email}`);
    const body = await r.json();

    // if email is in use show login panel
    if (body.email_in_use === true) {
      this.emailHiddenTarget.value = email;
      this.loginLinkTarget.classList.add('d-block');
      this.showLoginModal();
    }
  }

  loginModalHidden() {
    toastr.error(
      `Email ${this.emailInputTarget.value} is already in use, please sign in or use a different email`
    );
  }

  showLoginModal() {
    $(this.loginModalTarget).modal('show');
  }

  updateRegionSelection() {
    const cId = parseInt(this.countryTarget.value);
    const regions =
      cId > 0
        ? this.regions
            .filter((e) => e.country_id == cId)
            .map((e) => ({ value: e.id, text: e.region }))
        : [];
    this.ts.clear();
    this.ts.clearOptions();
    this.ts.addOptions(regions);
  }
}
