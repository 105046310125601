import { Controller } from 'stimulus';

export default class extends Controller {
  pageZoomSelected(event) {
    const elementId = event.target.dataset.container;
    const zoomLevel = Number(event.currentTarget.value || 1);
    this.setZoomLevel(document.getElementById(elementId), zoomLevel);
  }

  setZoomLevel = (element, zoomLevel) => {
    const targetElement = element;

    targetElement.style.zoom = zoomLevel;
    targetElement.style['-ms-zoom'] = zoomLevel;
    targetElement.style['-webkit-zoom'] = zoomLevel;
    targetElement.style['-moz-transform'] = `scale(${zoomLevel})`;
    targetElement.style['-moz-transform-origin'] = '0 0';
  }
}
