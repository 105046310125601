import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['cannedMessage', 'messageInput'];

  connect() {
    this.cannedMessageTargets.forEach(this.addClickListener.bind(this));
  }

  addClickListener(el) {
    el.addEventListener('click', () => {
      this.messageInputTarget.value = el.dataset.cannedMessage;
    });
  }
}
