import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.cardClicked.bind(this));
    this.requestIsSelected =
      this.element.dataset.requestCardSelected === 'true';
    // if (this.requestIsSelected) {
    //   this.element.scrollIntoView(true);
    // }
  }

  cardClicked(e) {
    const leadId = e.currentTarget.closest('.request-card').dataset.leadGuid;

    const url = new URL(window.location);
    const query = new URLSearchParams(url.search);
    query.set('lead_guid', leadId);
    window.location = `${url.origin}${url.pathname}?${query}`;
  }
}
