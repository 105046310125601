import Value from './value';

export default class DataBuilder {
  constructor(inputJsonFormString) {
    this.inputJsonFormString = inputJsonFormString;
    this.dataObject = null;
  }

  asString() {
    return new URLSearchParams(this.asObject()).toString();
  }

  asObject() {
    if (!this.dataObject) {
      this.build();
    }

    return this.dataObject || {};
  }

  build() {
    this.dataObject = {};
    if (Value.isPresent(this.inputJsonFormString)) {
      const inputData = JSON.parse(this.inputJsonFormString);
      this.append(inputData);
    }
  }

  appendPair(key, value) {
    if (!this.dataObject)
      this.dataObject = {};

    this.dataObject[key] = value;
  }

  append(obj, prefix) {
    Object.keys(obj).forEach((name) => {
      const value = obj[name];
      if (Value.isObject(value)) {
        this.append(value, name);
      } else {
        if (prefix) {
          this.dataObject[`${prefix}[${name}]`] = value;
        } else {
          this.dataObject[name] = value;
        }
      }
    });
  }
}
