import Value from './value';
export default class Element {

  static setValue(el, value) {
    if (!el) return;
    let currentValue = Element.value(el);
    if (Element.isSelect(el) && Value.isBlank(currentValue) && Value.isBlank(value) && Value.isPresent(el.attributes['prompt'])) {
      return;
    }
    if (Element.isSelect(el) && Array.isArray(value)) {
      let optionElem = document.createElement('option');
      el.innerHTML = '';
      el.add(optionElem);
      value.forEach((option) => {
        optionElem = document.createElement('option');
        optionElem.text = option;
        el.add(optionElem);
      });
    } else {
      el.value = value;
    }
  }

  static closestButton(el) {
    if (Element.isButton(el)) {
      return el;
    } else {
      if (Element.isButton(el.parentElement)) {
        return el.parentElement;
      } else {
        return el.closest('button');
      }
    }
  }
  static closestAnchorLinkOrButton(el) {
    return Element.closestAnchorLink(el) || Element.closestButton(el);
  }

  static closestAnchorLink(el) {
    if (Element.isButton(el)) {
      return el;
    } else {
      if (Element.isAnchorLink(el.parentElement)) {
        return el.parentElement;
      } else {
        return el.closest('a');
      }
    }
  }

  static isButton(el) {
    return el && (el.nodeName == 'button' || el.nodeName == 'BUTTON');
  }

  static isAnchorLink(el) {
    return el && (el.nodeName == 'a' || el.nodeName == 'A');
  }

  static isSelect(el) {
    return el && el.nodeName == 'SELECT';
  }

  static append(element, newElement) {
    let temp = document.createElement('template');
    temp.innerHTML = newElement;
    return element.appendChild(temp.content.firstChild);
  }

  static value(el) {
    return el.type == 'checkbox' ? el.checked : el.value;
  }

  static isPresent(v) {
    v != null && v != '';
  }

  static isBlank(v) {
    v == null || v == '';
  }

  static show(el) {
    let formGroup = el.closest('.form-group');
    if (formGroup) {
      formGroup.classList.remove('d-none');
      formGroup.classList.remove('fade-enter');
    }
    el.classList.remove('d-none');
    el.classList.remove('fade-enter');

    Element.enable(el);
  }

  static hide(el) {
    let formGroup = el.closest('.form-group');
    if (formGroup) {
      formGroup.classList.add('d-none');
      formGroup.classList.add('fade-enter');
    }
    el.classList.add('d-none');
    el.classList.add('fade-enter');

    Element.disable(el);
  }

  static toggleEnabled(el) {
    if (Element.isDisabled(el)) {
      Element.enable(el);
    } else {
      Element.disable(el);
    }
  }

  static isDisabled(el) {
    return el.disabled || el.readOnly;
  }

  static disable(el) {
    el.setAttribute('disabled', true);
    el.setAttribute('readonly', true);
    el.disabled = true;
    el.readOnly = true;
  }

  static enable(el) {
    el.removeAttribute('disabled');
    el.removeAttribute('readonly');
    el.disabled = false;
    el.readOnly = false;
  }

  static isVisible(el) {
    return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
  }
}
