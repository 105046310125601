import { Controller } from 'stimulus';
import $ from 'jquery';
import Rails from '@rails/ujs';
import jspreadsheet from 'jspreadsheet-ce';
import { useResize } from 'stimulus-use';

export default class extends Controller {
  static targets = ['sheet'];

  connect() {
    const editable = this.element.dataset.enabled === 'true';
    const data = JSON.parse(this.element.dataset.data);
    this.columns = [
      {
        title: 'Id',
        name: 'id',
        type: 'numeric',
        mask: '0',
        width: 60,
        align: 'right',
        readOnly: true,
      },
      ...this.editableColumns(),
      {
        title: ' ',
        width: 50,
        align: 'center',
      },
    ];

    this.showNotice();

    this.spreadsheet = jspreadsheet(this.sheetTarget, {
      allowComments: false,
      allowDeleteColumn: false,
      allowInsertColumn: false,
      allowRenameColumn: false,
      columnDrag: false,
      columnSorting: false,
      editable,
      defaultColAlign: 'left',
      minSpareRows: 1,
      minDimensions: [this.columns.length, 1],
      tableOverflow: true,
      columns: this.columns,
      data,
      onload: this.spreadsheetLoaded,
      updateTable: this.spreadsheetUpdated,
      onbeforepaste: this.spreadsheetPasted,
    });

    useResize(this);
  }

  resize({ width }) {
    if (this.spreadsheet) {
      this.spreadsheet.content.style.maxWidth = `${width}px`;
      // Remove default styles applied by the "tableOverflow: true" option, that we
      // need to use for the dropdown cells to expand correctly outside the table
      // overflow area on the Y axis.
      this.spreadsheet.content.style.maxHeight = 'none';
      this.spreadsheet.content.style.boxShadow = 'none';
    }
  }

  spreadsheetLoaded = instance => {
    this.applyHeadersHTML(instance);
  };

  applyHeadersHTML = instance => {
    // This is apparently the suggested way to set the table headers as HTML:
    // https://github.com/jspreadsheet/ce/issues/988
    instance.jspreadsheet.headers.forEach(header => {
      /* eslint-disable no-param-reassign */
      header.style.whiteSpace = 'pre-wrap';
      header.innerHTML = header.textContent;
      /* eslint-enable no-param-reassign */
    });
  };

  spreadsheetUpdated = (instance, cell, col, row) => {
    if (col === this.columns.length - 1) {
      $(cell).prop('class', 'readonly');

      // Don't display the delete button on empty rows as there's nothing to delete
      const rowHasData =
        instance.jspreadsheet.getRowData(row).filter(val => !!val).length > 0;

      if (rowHasData) {
        $(cell).html(`
        <a href="#!" class="text-danger" data-row="${row}" data-action=${this.identifier}#deleteRow>
          <i class="fas fa-trash"></i>
        </a>
      `);
      }
    }
  };

  spreadsheetPasted = (instance, clipboard, x, y) => {
    const { headers, rows } = instance.jspreadsheet;

    // There are multiple reports of performance issues when pasting large amounts
    // of data, this fix was found here, and seems to be helping so far:
    // https://github.com/jspreadsheet/ce/issues/1440#issuecomment-923076081
    const csvData = instance.jspreadsheet.parseCSV(clipboard, '\t');
    if (csvData != null && csvData.length > 0 && csvData[0].length > 0) {
      const colsNeeded = parseInt(x, 10) + csvData[0].length - headers.length;
      if (colsNeeded > 0) {
        instance.jspreadsheet.insertColumn(colsNeeded);
      }

      const rowsNeeded = parseInt(y, 10) + csvData.length - rows.length;
      if (rowsNeeded > 0) {
        instance.jspreadsheet.insertRow(rowsNeeded);
      }
    }
  };

  addEmptyRow(event) {
    event.preventDefault();

    this.spreadsheet.insertRow();
  }

  deleteRow(event) {
    event.preventDefault();

    this.spreadsheet.deleteRow(event.target.dataset.row);
  }

  async save(event) {
    const submitButton = event.currentTarget;

    // Manually disable the button to save changes using rails-ujs built-in disable method.
    Rails.disableElement(submitButton);
    const data = this.prepareDataForSubmission();

    const token = document.querySelector('meta[name="csrf-token"]').content;
    const response = await fetch(this.element.dataset.saveDataUrl, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    });

    const { status } = response;

    const formId = event.target.closest('.card-body').id;
    if (status >= 200 && status < 300) {
      sessionStorage.setItem('submittedSuccess', true);
      sessionStorage.setItem(formId, true);
      window.location.reload();
    } else {
      sessionStorage.setItem('submittedSuccess', false);
      sessionStorage.setItem(formId, false);
      this.showNotice();
      // Re-enable the submit button so the user can try again
      Rails.enableElement(submitButton);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  editableColumns() {
    throw new Error('Must implement `editableColumns()`');
  }

  // eslint-disable-next-line class-methods-use-this
  preprocessedData() {
    throw new Error('Must implement `preprocessedData()`');
  }

  showNotice = () => {
    [
      'employees',
      'vehicles',
      'vendors',
      'stock',
      'user-details',
      'cost-types',
      'customers',
      'event-quotes',
    ].forEach(key => {
      if (sessionStorage[key]) {
        const element = document.getElementById(key);
        this.toggleAlert(element);
      }
    });
  };

  toggleAlert = element => {
    const errorAlert = element.querySelector('.alert.alert-danger');
    const successAlert = element.querySelector('.alert.alert-success');
    const success = sessionStorage.submittedSuccess;

    if (success === 'true') {
      this.hideInlineBlock(errorAlert);
      this.showInlineBlock(successAlert);

      sessionStorage.removeItem('submittedSuccess');
      sessionStorage.removeItem(element.id);
    } else if (success === 'false') {
      this.hideInlineBlock(successAlert);
      this.showInlineBlock(errorAlert);

      sessionStorage.removeItem('submittedSuccess');
      sessionStorage.removeItem(element.id);
    }
  };

  hideInlineBlock = element => {
    element.classList.remove('d-inline-block');
    $(element).collapse('hide');
  };

  showInlineBlock = element => {
    element.classList.add('d-inline-block');
    $(element).collapse('show');
  };
}
