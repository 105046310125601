import Rails from '@rails/ujs';
import TomSelect from 'tom-select';
import 'tom-select/dist/css/tom-select.bootstrap4.css';

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'selectMatch',
    'numBays',
    'matchButton',
    'matchedStockItemId',
    'numBaysHidden',
  ];

  connect() {
    const { stockSearchPath, customStockId } = this.element.dataset;
    this.customStockId = customStockId;
    if (this.hasSelectMatchTarget) {
      this.tomSelect = new TomSelect(this.selectMatchTarget, {
        create: false,
        valueField: 'id',
        searchField: 'text',
        labelField: 'text',
        shouldLoad: (query) => query.length > 3,
        load: async (query, callback) => {
          const url = `${stockSearchPath}&term=${query}`;
          const r = await fetch(url);
          const body = await r.json();
          this.currentMatches = body.results[0].children;
          callback(body.results[0].children);
        },
      });
      this.selectMatchTarget.addEventListener(
        'change',
        this.itemSelected.bind(this)
      );
    }
    this.numBaysTarget.addEventListener(
      'change',
      this.numBaysUpdated.bind(this)
    );
  }

  itemSelected(e) {
    if (e.currentTarget.value === '') {
      this.numBaysTarget.disabled = true;
      this.matchButtonTarget.disabled = true;
      return;
    }

    const match = this.currentMatches.find((m) => {
      return m.id === parseInt(e.currentTarget.value);
    });
    this.matchedStockItemIdTarget.value = match.id;
    if (match.marquee) {
      // enable num bays
      this.numBaysTarget.disabled = false;
    } else {
      // disable
      this.numBaysTarget.disabled = true;
    }
    this.matchButtonTarget.disabled = false;
  }

  numBaysUpdated(e) {
    this.numBaysHiddenTarget.value = e.currentTarget.value;
  }

  noMatch(e) {
    e.preventDefault();
    // show on screen no match
    const ts = this.selectMatchTarget.tomselect;
    ts.addOption({ id: this.customStockId, text: 'No match' });
    ts.addItem(this.customStockId, true);
    ts.sync();
    // set the matched item to the same as the custom item and
    // save as a match
    this.matchedStockItemIdTarget.value = this.customStockId;
    Rails.fire(e.currentTarget.form, 'submit');
  }
}
